.DropdownButton {
    width: 100%;
    height: 28px;
    display: flex;
    padding: 6px 20px;
    align-items: center;
    justify-content: left;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.DropdownButton:hover {
    background: #F2F2F2;
}