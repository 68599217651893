.reference {
  padding: 24px 0 0 0;
  text-align: center;
}
.reference-search {
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
}
.reference-search > p {
  font-size: 17px;
  font-weight: 300;
  color: #4f4f4f;
  margin-bottom: 15px;
}

.reference-block {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #4f4f4f;
  font-weight: 300;
}
.reference-block > .custom_all > .css-yto0g7-control {
  box-shadow: none;
  border: 2px solid #56ccf2;
}

.reference-block
  > .custom_all
  > .css-yto0g7-control
  > .css-t083r6
  > .css-n3csis-ValueContainer
  > .css-slim6u-singleValue {
  margin-left: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #bfb5bd;
}

.reference-block > input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 34px;
  padding: 10px 17px;
  font-size: 13px;
  line-height: 16px;
  color: black;
  width: 325px;
  &:hover {
    border-color: #a7a7a7;
  }
  &:disabled {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
  }

  &::placeholder {
    color: #bfb5bd;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.clear_input__active {
  position: absolute;
  content: "";
  top: 15px;
  right: 38px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.clear_input {
  position: absolute;
  opacity: 0;
}

.reference-block > input:focus {
  border: 2px solid #56ccf2;
}

.reference-block .css-jxuxkg-container {
  width: 57%;
}

.btn-container {
  position: relative;
}

.reference__button {
  width: 100px;
  height: 40px;
  background: #26ae5f;
  border-radius: 2px;
  color: white;
  transition: all 0.4s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin: 10px 0 20px 0;
}

.clear-search {
  position: absolute;
  left: 23px;
  font-weight: 500;
  color: black;
  background: #e0e0e0;
  box-shadow: none;
}

.clear-search:hover {
  background: #f2f2f2;
  box-shadow: none;
}
.reference__button:hover {
  opacity: 0.7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}

.reference-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 400px;
  min-height: 250px;
  padding: 10px;
}

.reference-not-found > img {
  width: 100px;
  height: 100px;
}
.reference-not-found > p {
  color: #757575;
}

.reference-not-found > .p {
  font-size: 24px;
  font-weight: 700;
}

.reference > table {
  margin: 0px auto 20px auto;
  width: 50%;
  border-collapse: collapse;
  font-size: 13px;
}

.reference > table > tbody > tr {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  transition: all 0.4s;
}
.reference-block > .custom_all > .css-1uzsk4b-menu {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-5zgcwc-placeholder {
  text-align: left;
}

.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-t083r6
  > .css-5zgcwc-placeholder,
.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-t083r6
  > .css-slim6u-singleValue {
  margin-left: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.reference > table > tbody > tr:hover {
  background: transparent;
}

.reference > table > tbody > tr > td {
  padding: 8px;
}

.reference > table > tbody > tr > td:nth-child(2) {
  text-align: left;
}
