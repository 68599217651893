.passport {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background: white;
    z-index: 10;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}


.passport__wrapper {
    max-width: 555px;
    border: 1px solid #EBEBEB;
    border-radius: 12px;
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.passport__wrapper > *:not(:last-child) {
    margin-bottom: 24px;
}

.passport__box img {
    margin-right: 30px;
}

.passport__box p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.passport__box {
    padding: 24px 16px;
    background: #FEFFCA;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.passport__btn-download {
    border: 1px solid #C9C9C9;
    border-radius: 8px;
    padding: 36px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ADADAD;
    width: 100%;
}
.passport__file{
    position: relative;
    width: 100%;
}
.passport__file input[type="file"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.passport__btn-next {
    padding: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0361D0;
}
