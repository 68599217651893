.MiniButton {
    width: 28px;
    height: 28px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: .2s ease;
    cursor: pointer;
    z-index: 0;
}

.MiniButton:hover {
    background-color: rgba(0,0,0,.1);
    transition: .2s ease
}
.MiniButton:focus + .Dropdown__menu,
.Dropdown__menu:hover{
    display: flex !important;
}

.MiniButton_disabled {
    opacity: .6;
    cursor: not-allowed;
}

.MiniButton_disabled:hover {
    background-color: rgba(0,0,0,0);
}
