.controller__user__wrapper {
    display: flex;
}

.controller__user__row {
    background: #F4F1EC;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
}

.controller__user__text__row {
    display: flex;
    justify-content: space-between;
}

.controller__user__text__row p {
    font-weight: 400;
    font-size: 13px;
}

.controller__user__text__row span {
    font-weight: normal;        
}


.controller__user__row:not(:first-child) {
    margin-left: 20px;
}

.controller__user__detail {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.controller__user__h2 {
    font-size: 18px;
    color: #67674D;
    margin-bottom: 16px;
}

.controller__user__detail__table {
    width: 100%;
}

.controller__user__detail__table thead {
    display: block;
}

.controller__user__detail__table thead th {
    font-size: 16px;
}

.controller__user__detail__table tr {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 8px 20px;
    text-align: left;
}

.controller__user__text {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
    margin-top: auto;
}

.controller__user__scroll {
    max-height: 242px;
    overflow-y: auto;
    display: block;
}

.controller__user__text span {
    background: white;
    color: #EC4E4D;
    padding: 2px 4px;
    border-radius: 5px;
}

.controller__user__box {
    padding: 10px 20px;
    background: #DCD9D0;
    border-radius: 5px;
}

/*.controller__user__detail__table tr:not(:last-child) {
    border-bottom: 1px solid gray;
}*/
