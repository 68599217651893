.controller__btn {
    background: #F2F2F2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #000000;
}

.history_controller :global(.Modal__header) {
    background-color: #009640;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    margin: -20px -20px 20px;
}

.history_controller {
    position: absolute;
    width: 100%;
    display: flex;
    max-height: 100%;
    max-width: 100%;
    flex-direction: column;
    top: 0;
    left: 0;
    transform: inherit;
    height: 100%;
    padding: 20px;
}

.controller__header li {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
}

.controller__header button {
    background: #F8F8F8;
    border-radius: 6px;
    padding: 8px;
}

.controller__header li span {
    margin-left: 8px;
    font-weight: 400;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    color: #000000;
}

.controller__container {
    display: flex;
    flex-direction: column;
    margin: -20px;
    overflow-x: auto;
    max-height: 454px;
    padding-bottom: 30px;
}

.controller__box__container {
    padding: 8px;
    background: #FAF9F9;
    border-radius: 6px;
    width: max-content;
    display: flex;
}

.controller__box__container:hover {
    background: #E0E0E0;
    transition: .5s ease;
}

.controller__grid {
    margin-top: 20px;
}

.controller__grid li {
    display: grid;
    grid-template-columns: max-content 4fr 2fr max-content max-content max-content;
    grid-column-gap: 30px;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
}

.controller__header {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 10px 20px;
    background: #F2F2F2;
    position: sticky;
    top: 0;
}

.controller__bottom {
    position: absolute;
    background: white;
    width: 100%;
    bottom: 0;
    padding: 5px 0;
}

.controller__list {
    list-style: none;
    margin-top: 5px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    background: #F2F2F2;
    width: 200px;
}

.controller__list li {
    padding: 10px;
    transition: .5s ease;
}

.controller__list li:hover {
    background: gray;
    cursor: pointer;
    color: white;
}

.controller__list li:not(:last-child) {
    margin-bottom: 10px;
}

.controller__box {
    padding: 10px 20px;
    position: relative;
}


/*Modal*/
.modal__controller__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.modal__controller__row {
    display: flex;
    align-items: center;
}

.modal__controller__row:not(:last-child) {
    margin-bottom: 10px;
}

.modal__controller__field {
    position: relative;
    width: 100%;
}

.modal__controller__field :global(.error) {
    position: absolute;
    font-size: 11px;
    color: red;
    bottom: 2px;
    transform: translate(0, 100%);
}

.modal__controller__row input {
    width: 100%;
    height: 31px;
    padding: 5px 10px;

    background: #FFFFFF;
    border: 1px solid #616161;
    border-radius: 6px;
}

.modal__controller__row p {
    width: 80px;
    margin-right: 20px;
    min-width: 80px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.modal__controller__finger__list {
    list-style: none;
    width: 100%;
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    gap: 9px
}

.modal__controller__finger__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal__controller__finger__box {
    width: 63px;
    height: 63px;
    border: 1px solid #626262;
    border-radius: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
}

.modal__controller__finger__button {
    background: #C91D1D;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    padding: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.modal__controller__add {
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    z-index: 30;
    transform: translate(-50%, -50%);
    padding: 20px;
    max-width: 415px;
    width: 100%;
    border-radius: 6px;
}
