.Alert {
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #27ae60;
    color: white;
    border-radius: 4px;
    margin: 20px;
}

.Alert_success {
    background-color: #27ae60;
}
.Alert_error {
    background-color: #ef5b5b;
}
.Alert_info {
    background-color: #00a2ff;
}