.Dropdown {
    position: relative
}

.Dropdown__menu {
    position: absolute;
    right: 0px;
    z-index: 9999;
    display: none;
    flex-direction: column;
    /* min-width: 140px; */
    width: 150px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.Dropdown__menu_disabled {
    display: none
}

/* Таблица */

.table {
    background-color: #fff;
}

.table__dropdown__content {
    border: 1px solid #d7d7d7;
    display: none;
    position: absolute;
    background-color: #fff;
    width: max-content;
    white-space: nowrap;
    min-width: 150px;
    /*height: 164px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 9px 0;
}

.edit_rent-document__right .table__dropdown__content .submenu__dropdown__btn {
    white-space: normal;
}

.table__dropdown__content:hover {
    display: block;
}

.table__dropdown__content a {
    color: black;
    padding: 7.5px 26px;
    text-decoration: none;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}

/* Change color of dropdown links on hover */
.table__dropdown__content button:hover {
    background-color: #F2F2F2;
}

.submenu__dropdown__btn {
    width: 100%;
    padding: 0 16px;
    text-align: left;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.submenu__dropdown__floated--active {
    background-color: #F2F2F2;
}

.submenu__dropdown__floated {
    width: 100%;
    padding: 4px 18px;
    text-align: left;
}

.submenu__dropdown__btn img {
    position: absolute;
    right: 10px;
    top: 10px;
}

@media screen and (max-width: 1455px) {
    .table__container {
        align-items: center;
    }
}

.show {
    display: block;
}

.submenu a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu a span {
    margin-right: 49px;
}

.submenu:hover .submenu__dropdown__content {
    display: block;
}

/*  */
.submenu__2 {
    position: relative;
}

.submenu {
    position: relative;
}

.submenu__dropdown__content {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, 0);
    background-color: #fff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border: 1px solid #d7d7d7;
}

.submenu__2 a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu__2 a span {
    margin-right: 75px;
}

.submenu__2:hover .submenu__dropdown__content__2 {
    display: block;
}

.submenu__dropdown__content:hover + button {
    background-color: #F2F2F2;
}

.submenu__dropdown__content__3 {
    display: none;
    position: absolute;
    left: 161px;
    top: 8px;
    background-color: #fff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border: 1px solid #d7d7d7 !important;
}

.submenu__3 a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu__3 a span {
    margin-right: 52px;
}

.submenu__3:hover .submenu__dropdown__content__3 {
    display: block;
}

.table__row__header {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
}

.table__row__header th {
    padding: 12px 13px;
    height: 40px;
}

.table__row__tr {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
}

.table__row__tr td {
    padding: 12px 25px;
}

.table__row__tr__td {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
}

.table__row__tr__td td {
    padding: 0 13px;
}

.submenu__dropdown__content__3:hover + button,
.submenu__dropdown__content__2:hover + button,
.submenu__dropdown__content:hover + button {
    background: #f2f2f2;
}
