.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0px 3px 0px 40px;
  width: 78%;
}
.checkbox {
  font-size: 14px;
}
#checkAllDeleted {
  width: 21px;
  height: 21px;
  margin-right: 20px;
}
