.Start {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 0 0;
    box-sizing: border-box;
    display: grid; 
    grid-template-columns: 460px 460px 460px; 
    gap: 58px 85px;
    align-items: start;
    justify-content: left;
}

@media(max-width: 1600px) {
    .Start {
        gap: 40px 40px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}