.container {
    margin: 0 auto;
    padding: 24px 0;
    height: 100%;
}

.container__wrapper {
    display: flex;
    margin-bottom: 24px;
}

.container__liner {
    background: white;
    padding: 16px 24px;
    border-radius: 16px;
}

.container__table {
    width: 100%;
    background: white;
    padding: 16px 24px;
    border-radius: 16px;
}

.list {
    display: flex;
}

.wrapper {
    display: flex;
    align-items: center;
}

.custom__tooltip__img {
    display: flex;
    margin-right: 8px;
}

.custom__tooltip__list {
    display: flex;
    flex-direction: column;
    color: black;
}

.custom__tooltip__list :global(.label) {
    display: flex;
}


.tooltips__widget__row {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: #000;
}


.custom__tooltip__wrapper {
    display: flex;
    align-items: center;
}

.experience__line {
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.experience__line > :global(div) {
    background: #1A73E8;
    border-radius: 8px;
    height: 8px;
}

.experience__right {
    display: flex;
}

.custom__tooltip > :global(.label:not(:first-child)) {
    margin-bottom: 16px;
}

.custom__tooltip {
    letter-spacing: .025em;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    background: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    font-size: 13px;
    line-height: 16px;
    padding: 12px 16px;
}

.custom__tooltip__staffing {
    display: flex;
    flex-direction: column;
}

.custom__tooltip__staffing__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.custom__tooltip__staffing__wrapper :global(span) {
    font-weight: 600;
    margin-left: 8px;
}

.custom__tooltip__staffing__top {
    width: 100%;
    margin-bottom: 8px;
}

.custom__tooltip__staffing__box:not(:last-child) {
    margin-bottom: 8px;
}


.custom__tooltip__staffing__box {
    height: max-content;
    padding: 8px;
    background: #C1D6FF;
    border-radius: 8px;
    max-width: 500px;
}

.custom__tooltip__staffing__box__red {
    background: #FFD4D4;
}

.select {
    min-width: 107px;
}

.container__table__top__wrapper span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;
}

.container__table__top__wrapper {
    display: flex;
}

.container__table__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.list li:not(:last-child) {
    margin-right: 2px;
}

.list li {
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 16px;
    color: #828282;
    transition: .5s ease;
}

.list li:global(.react-tabs__tab--selected) {
    background: #E8F0FE !important;
    border-radius: 8px;
    color: #1967D2;
}

.list li:hover {
    background: #F2F2F2;
    border-radius: 8px;
    cursor: pointer;
}

.container__tabs {
    display: flex;
    flex-direction: column;
}

.container__grafic :global(.react-tabs__tab-panel.react-tabs__tab-panel--selected) {
    display: flex;
}

.container__grafic :global(.react-tabs__tab-panel) {
    display: none;
}

.container__table__info {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    max-width: 268px;
}

.container__table__info__column:first-child {
    margin-right: 8px;
}


.container__table__info__column {
    background: white;
    padding: 10px;
    border-radius: 16px;
    flex: 1 1;
}

.container__table__info__label__green {
    color: #137333;
    background: #E6F4EA;
}

.container__table__info__label__pink {
    background: #FCE8E6;
    color: #C5221F;
}

.container__table__info__text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px 12px;

    color: #000000;
}

.container__table__info__label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    padding: 16px 10px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.container__widget {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
}

.container__widget__diagrams {
    margin-right: 16px;
}

.container__widget__diagrams__info {
    background: #FFDAF3;
    border-radius: 8px;
    padding: 8px;
}


.container__widget__state__top ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
}

.container__widget__state__top {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    color: #000000;
}

.container__widget__history table {
    border-collapse: collapse;
}


.container__widget__history table tr:before {
    content: "";
    top: 50%;
    left: -4px;
    transform: translate(0, -50%);
    width: 7px;
    height: 7px;
    position: absolute;
    background: #137333;
    border-radius: 100%;
}

.container__widget__history table tr:global(.deleted):before {
    background: #C5221F;
}

.container__widget__history table tr td:not(:first-child) {
    margin-right: 10px;
}

.container__widget__history table tr {
    position: relative;
    padding: 6px 0 6px 16px;
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.container__widget__history table tr {
    border-left: 1px solid #E0E0E0;
}

.container__widget__item__column {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
}

.container__widget__state__top h4 {
    margin-bottom: 8px !important;
}

.container__widget__state__top ul p {
    margin-right: 8px;
}

.container__widget__state__top span {
    font-weight: 600;
}

.container__widget__diagrams__info h6:not(:last-child) {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #2E2E2E;
    margin-bottom: 4px;
}

.container__widget__diagrams__info ul > li {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    color: #2E2E2E;
}

.container__widget__diagrams__info ul > li span {
    font-weight: 600;
}

.container__widget__diagrams__info ul > li:not(:last-child) {
    margin-bottom: 4px;
}

.container__widget__diagrams__info ul {
    list-style: none;
    padding: 0px;
}

.container__widget__state {
    display: flex;
    flex-direction: column;
}


.container__widget {
    gap: 12px;
}

.container__widget__grid {
    display: grid;
    width: 100%;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.container__widget__box {
    padding: 16px;
    background: #FFFFFF;
    border-radius: 16px;
}

.container__widget__image__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container__widget__image__text span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.container__widget__today {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
}

.container__widget__info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tooltips__widget__maxMin > div {
    display: flex;
}

.tooltips__widget__maxMin > div span {
    display: flex;
    width: 80px;
}

.tooltips__widget__maxMin > div :global(span.age) {
    width: 60px;
}

.tooltips__widget__maxMin p {
    margin-bottom: 16px;
}

.tooltips__widget__maxMin:not(:first-child) {
    margin-right: 8px;
}

.custom__tooltip__grid > div {
    max-width: 500px;
    width: max-content;
}

.custom__tooltip__grid {
    display: grid;
    grid-template-columns: minmax(0, max-content)  minmax(0, max-content);
    column-gap: 32px;
    row-gap: 12px;
}

.custom__tooltip__grid :global(.custom__tooltip__wrapper) {
    flex-basis: 50%;
}

.tooltips__widget__maxMin {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    white-space: nowrap;
    color: black;
}

.tooltips__widget__maxMin__wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}


.container__widget__item h4 span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.container__widget__item[data-for]:hover {
    cursor: help;
}

.container__widget__item h4 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 12px;
    margin-bottom: 8px;
}

.tooltips__widget p {
    color: #000000;
}


.tooltips__widget ul li {
    color: #000000;
    list-style: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.tooltips__widget :global(.label):not(:first-child) {
    font-size: 13px;
    line-height: 15px;
}


.tooltips__widget ul li span {
    margin-left: 4px;
    font-weight: 600;
}

.tooltips__widget ul li:not(:last-child) {
    margin-bottom: 4px;
}

.tooltips__widget {
    color: #000000;
    background: #FFFFFF;
    opacity: 1 !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.container__widget__image img {
    height: 36px;
}

.container__widget__image {
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #CDCDCD;;
}

.tooltips__widget__wrapper {
    display: flex;
    align-items: center;
}

.tooltips__widget__list {
    max-width: 120px;
    padding-left: 28px;
}

.tooltips__widget__list li div {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translate(0, -50%);
}

.tooltips__widget__list li p {
    display: flex;
    min-width: 50px;
    font-size: 13px;
}

.tooltips__widget__list li {
    position: relative;
    justify-content: space-between;
}


.container__widget__worker:not(:last-child) {
    margin-right: 10px;
}

.container__widget__item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 96px;
    border-style: solid;
    border-color: #e7e7e7;
    border-width: thin;
}

.container__widget__wrapper {
    display: flex;
    flex: 1 1;
}

