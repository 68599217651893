/*Стили карточки информации*/

.kadri__item__content {
  margin-right: 26px;
}

.kadri__item__info {
  width: 434px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: #313131;
  font-size: 13px;
  font-weight: 400;
  background-color: #fff;
  margin-left: 8px;
}

.table__dropdown__column ul > li > span {
  width: 100%;
  display: flex;
  padding: 4px 9px;
}

.table__dropdown__hover > li > span {
  width: 100%;
  display: flex;
  padding: 4px 9px;
}

.table__dropdown__column ul ul > li:before {
  content: "-";
  position: absolute;
  font-size: 20px;
  left: 9px;
  top: 50%;
  transform: translate(0, -50%);
}

.table__dropdown__hover ul > li::before {
  content: "-";
  position: absolute;
  font-size: 20px;
  left: 9px;
  top: 50%;
  transform: translate(0, -50%);
}

.edit_rent-document__wrapper {
  width: 100%;
  position: relative;
}

.document_button_wrapper {
  position: absolute;
  left: 50px;
}

.edit_rent-document__wrapper .submenu__dropdown__btn {
  white-space: normal;
}

.table__dropdown__column ul ul > li {
  padding: 4px 18px;
  position: relative;
}

.table__dropdown__hover ul > li {
  padding: 4px 18px;
  position: relative;
}

.table__dropdown__column ul ul > li {
  cursor: pointer;
}

.table__dropdown__hover ul > li {
  cursor: pointer;
}

.table__dropdown__column ul ul {
  list-style: none;
}

.table__dropdown__hover ul {
  list-style: none;
}

.table__dropdown__column .hover:hover {
  background-color: #f2f2f2;
}
.table__dropdown__hover .hover:hover {
  background-color: #f2f2f2;
}

.table__dropdown__hover > li:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.table__dropdown__column ul {
  list-style: none;
}
.table__dropdown__hover {
  list-style: none;
}

.table__dropdown__column {
  position: relative;
}

/* .submenu__dropdown__btn__hover:hover + .table__dropdown__hover {
  opacity: 1;
  pointer-events: all;
} */

.table__dropdown__hover {
  position: absolute;
  left: 10px;
  top: 38px;
  opacity: 1;
  pointer-events: none;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(100%, 0);
  font-size: 13px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  width: max-content;
  white-space: nowrap;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 9px 0;
}

.conditions__transfer input::placeholder {
  color: hsl(0, 0%, 50%);
}

.conditions__transfer input {
  width: 100%;
  border: 1px solid rgba(193, 193, 193, 0.8);
}

.conditions__transfer .conditions__row .error {
  bottom: 1px;
  font-size: 10px;
}

.conditions__transfer .conditions__content-left p {
  margin: 0 0 10px 0;
}

.conditions__transfer .conditions__row {
  margin-bottom: 10px;
}

.conditions__transfer label {
  width: 100%;
}

@media screen and (max-width: 1155px) {
  .kadri__item__info {
    width: 390px;
  }
}

.kadri__item__info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 26px 8px;
}

.kadri__item__info__header-text {
  color: #313131;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.kadri__item__info__close-btn {
  border: none;
  position: relative;
  left: 2px;
  width: 28px;
  height: 28px;
  background-color: transparent;
  cursor: pointer;
}

.kadri__item__info__close-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.kadri__item__info__close-btn:hover {
  background-color: #ececec;
}

.kadri__item__info__close-btn:hover .info__close-icon {
  filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
}

.kadri__item__info__hr {
  width: 392px;
  margin: 0 auto;
  height: 1px;
  background: #d7d7d7;
}

.kadri__item__info__content {
  margin-top: 22px;
  padding-bottom: 19px;
}

.kadri__item__info__content__2 {
  padding: 15px 25px 15px;
  display: flex;
  flex-direction: column;
}

.kadri__item__info__row {
  display: flex;
  align-items: center;
  margin: 0 0 2px 0;
  /* padding: 5px; */
  border-radius: 3px;
}

.kadri__item__info__row:hover p {
  background: #f2f2f2;
}

.kadri__item__info__row:hover .kadri__item__info__box-left,
.kadri__item__info__row:hover .kadri__item__info__box-right {
  background: transparent;
}

.kadri__item__info__row p {
  align-items: center;
  /* margin: 0 0 7px 0; */
  padding: 6px;
  border-radius: 3px;
}

.kadri__item__info__row img {
  width: 26px;
  margin: 0 24px 0 0;
}

/* .kadri__item__info__row:first-child {
  margin-top: 25px;
}
.kadri__item__info__row:last-child {
  margin-bottom: 15px;
}
.kadri__item__info__row:hover {
  cursor: pointer;
} */

.kadri__item__info__box-left {
  width: 140px;
}

.kadri__item__info__wrapper {
  width: 140px;
  display: flex;
  align-items: center;
}

.kadri__item__info__icon {
  width: 14px !important;
  height: 14px !important;
  margin: 0 0 0 7px !important;
  cursor: pointer;
}

.kadri__item__info__box-right {
  width: 280px;
}

.kadri__item__info__box-left p {
  margin-bottom: 15px;
}

.kadri__item__info__box_tab {
  padding-left: 20px;
}

.kadri__item__info__row {
  display: flex;
  align-items: flex-start;
}

.kadri__item__info__box_token-tab {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
}

/*.kadri__item__info__box-left p:nth-child(2) {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.kadri__item__info__box-left p:nth-child(4) {*/
/*  margin-bottom: 18px;*/
/*}*/

/* .kadri__item__info__box-left p span:nth-child(5) {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.kadri__item__info__box-left p:nth-child(5) {
  margin-bottom: 31px;
  display: flex;
  align-items: center;
} */
/* .kadri__item__info__box-left p span:nth-child(6) {
  display: flex;
  align-items: center;
} */

/* .kadri__item__info__box-left p:nth-child(6) {
  display: flex;
  align-items: center;
} */

.kadri__item__info__box-right p {
  margin-bottom: 15px;
}

.kadri__item__info__span {
  background: #ffdc64;
}

.kadri__item__info__box-right-section {
  display: flex;
  align-items: center;
}

.kadri__item__info__box-right-button {
  border: none;
  position: relative;
  bottom: 7px;
  left: 15px;
  width: 116px;
  height: 36px;
  background: #0dadd9;
  border-radius: 3px;
  color: #fff;
  padding-left: 20px;
  transition: all 0.2s ease-in-out;
}

.kadri__item__info__box-right-button:hover {
  background: #3cb5d6;
}

.kadri__item__info__box-right-icon {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

/* Стили добавления токена */

.add__token {
  width: 352px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

@media screen and (max-width: 1455px) {
  .add__token {
    margin-bottom: 27px;
  }
}

.add__token__left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.add__token__left img {
  padding: 11px 18px;
  background-color: #ffdf71;
  margin-left: 3px;
  border-radius: 0px 4px 4px 0px;
}

.add__token__left__active img {
  background-color: #ffc709;
  cursor: pointer;
}

.add__token__close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.add__token__close img {
  padding: 7px;
  border-radius: 3px;
}

.add__token__close img:hover {
  background-color: #ececec;
  cursor: pointer;
}

/* Стили карточки поиска */
.add__token__search {
  width: 50px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #ffdc64;
  transition: 0.2s ease;
  margin-left: 1px;
}

.add__token__search:hover {
  background: #f2c94c;
}

.add__token__search__disabled {
  background: #fafafa;
  border: 2px solid #efefef;
}

.add__token__search__disabled svg {
  opacity: 0.6;
}

.add__token__search__disabled:hover {
  background: #fafafa;
}

.add__token__search__2 {
  position: absolute;
  right: 0;
  /*z-index: 9999;*/
  /*top: 193px;*/
  /*right: 186px;*/
  width: 399px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.add__token__search__2 input:focus {
  border: 3px solid #56ccf2;
}

.add__token__search__2 input {
  font-size: 13px;
}

/* Стили карточки Кадровых документов */
.kadri__item__info__box__doc-left {
  margin-top: 22px;
  margin-left: 25px;
  width: 140px;
}

.kadri__item__info__box__doc-left p:hover {
  cursor: pointer;
}

.kadri__item__info__box__doc-right p:hover {
  cursor: pointer;
}

.kadri__item__info__box__doc-right {
  width: 320px;
  margin-right: 244px;
  margin-top: 22px;
}

.kadri__item__info__box__doc-left p {
  margin-bottom: 10px;
  text-align: center;
  margin-left: -15px;
}

.kadri__item__info__box__doc-right p {
  margin-bottom: 20px;
}

/* Стили карточки отпусков */

.conditions {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 280px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .conditions__row img {
  position: absolute;
} */

/* .conditions__header {
  padding: 15px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: black;
  font-weight: 500;
} */

.conditions__close-icon {
  cursor: pointer;
}

.conditions__calendar-icon {
  position: absolute;
  right: 0;
}

/* .select {
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  height: 34px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  color: #bfb5bd;
  padding-left: 11px;
} */

.conditions__clock-icon--1 {
  left: 115px;
}

.conditions__clock-icon--2 {
  right: 0px;
}

.conditions__reward {
  right: 0px;
}

/* Стили карточки непрацездатності */

.conditions__nep {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__nep {
  margin-top: -25px;
  margin-left: 222px;
}

/* Стили карточки прогулов_1 */

.conditions__truancy {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 260px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__truancy {
  margin-top: -10px;
  margin-left: 222px;
}

/* Стили карточки прогулов_2 */

.conditions__truancy__2 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 210px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__truancy__2 {
  margin-top: -10px;
  margin-left: 222px;
}

/* Стили карточки поиска */

.search__container {
  background-color: #fff;
  width: 500px;
  height: 167px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 990;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search__list li {
  font-size: 13px;
  cursor: pointer;
  padding: 8px;
}

.search__list li:hover {
  background: #e3e3e3;
}

.search__list {
  position: absolute;
  bottom: -1px;
  transform: translate(0, 100%);
  background: white;
  width: 100%;
  z-index: 2;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  list-style: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.search__list.active {
  border: none;
}

.search__header {
  width: 100%;
  background-color: #219653;
  padding: 10px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search__header span {
  color: #fff;
  font-size: 16px;
}

.search__header img {
  padding: 7px;
  border-radius: 3px;
}

.search__header img:hover {
  background-color: #4daf77;
  cursor: pointer;
}

.search__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__content p {
  margin: 0 10px;
}

.input__row:not(:last-child) {
  margin-right: 12px;
}

.input__row {
  display: flex;
  align-items: center;
}

.input__row input {
  width: 60px;
  text-align: center;
  padding: 5px 10px;
}

.inputRight {
  background-color: #f8f8f8;
  height: 32px;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
  transition: 0.2s ease;
  cursor: pointer;
}

/*  */

.conditions__workdoc__4 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 320px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__header img {
  padding: 2px;

  border-radius: 3px;
}

.conditions__header img:hover {
  background-color: #f8f8f8;
}

/*  */
.conditions__workdoc__5 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 410px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__workdoc__6 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 460px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__workdoc__6 {
  margin-top: -18px;
  margin-left: 222px;
}

/* Стили для KadriCardsPersonEdit */
.tabcontent {
  overflow-y: scroll;
  height: 85%;
}

.tab__modal {
  position: fixed;
  width: 1020px;
  height: 590px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #fff;
}

.tab {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #009640;
}

.tab__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 1px);
  z-index: -1;
  background-color: #009640;
}

.tab__btn {
  padding-top: 20px;
  padding-left: 21px;
  height: 60px;
  overflow: hidden;
  /* border: 1px solid #ccc; */
  /* background-color: #009640; */
}

.tab__close {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.tab__close:hover {
  background-color: #4daf77;
  cursor: pointer;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  width: 168px;
  height: 40px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
}

.tab button:hover {
  background-color: #4daf77;
}

.tab button.active {
  background-color: #fff;
  color: #008137;
}

/* .tabcontent {
  padding: 65px 40px 40px 40px;
} */

/*  */

.table__container {
  /*//padding: 64px 40px 40px 40px;*/
}

.table__container table {
  width: 105.5%;
}

@media screen and (max-width: 1546px) {
  .table__container table {
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  /*.add__token__search__2{*/
  /*  top: 193px;*/
  /*  right: 1px;*/
  /*}*/
}

.table__place span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #535353;
}

.table__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 39px;
}

.table__value {
  display: flex;
  flex-direction: column;
}

.table__value__row {
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;
  align-items: flex-end;
}

.table__value__item__bottom {
  margin-top: auto;
}

.table__value__item {
  display: flex;
  flex-direction: column;
  color: #535353;
  margin-right: 16px;
}

.table__value__item span,
.table__value__item__input span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #535353;
  margin-bottom: 2px;
}

.null {
  margin-right: 0;
}

.table__value__item__row {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.table__value__item__input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
}

.item__input__check {
  background-color: #f8f8f8;
  height: 32px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 13px;
  padding-right: 13px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
  transition: 0.2s ease;
  cursor: pointer;
}

.item__input__check:focus + .table__dropdown__content,
.table__dropdown__content:hover {
  display: block;
  right: 0;
}

.left-0 {
  left: 0 !important;
}

.item__input__check span input {
  zoom: 1.5;
}

/*  */

.table__container__full {
  padding: 26px 40px 40px 40px;
}

/* Сетка размеров для таблицы */

.table-lg {
  width: 275px;
}

.table-md {
  width: 132px;
}

.tr-sm td {
  padding: 0;
  margin: 0;
}

.tr-sm td:input {
  border: none;
}

/*  */

.quota__container {
  padding: 21px 35px;
}

.quota__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
}

.quota__row__footer {
  display: flex;
  margin-top: 4px;
}

.quota__row__header {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.quota__row__header span {
  margin-right: 25px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.quota__row__table table {
  border-collapse: collapse;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.quota__row__table tr {
  display: flex;
  align-items: center;
  border: 0.5px solid #fff;
}

.quota__row__table td {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  height: 45px;
  justify-content: center;
}

.quota__row__table td:first-child {
  padding: 5px 7px;
}

.quota__row__table td:nth-child(2) {
  border-left: 2px solid #fff;
}

.quota__row__table td:last-child {
  border-left: 2px solid #fff;
}

.quota__row__table td span {
  border: 1px solid #bdbdbd;
  width: 177px;
  height: 26px;
  display: flex;

  align-items: center;
}

.quota__row__table td input,
.quota__row__table td select {
  width: 100%;
  height: 26px;
  border: 1px solid #bdbdbd;
  padding-left: 8px;
}

.quota__row__table td span:nth-child(2) {
  padding-left: 8px;
}

.quota__row__table__trash {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #fff;
}

.quota__row__table__trash:hover {
  cursor: pointer;
  background-color: #e0e0e0;
  transition: all 0.2s ease-in-out;
}

.quota__row__select {
  margin-right: 12px;
}

/*  */

.history__container {
  overflow-y: auto;
}

.history__container__settings {
  padding: 0;
}

.history__table {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
}

.history__table tr td {
  padding: 2px;
  padding-left: 8px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.history__table tr td:first-child {
  padding-left: 8px;
  border: 1px solid #f2f2f2;
  width: 120px;
}

.history__table tr td:nth-child(3) {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #f2f2f2;
}

.history__table tr td:last-child {
  border: 1px solid #f2f2f2;
  border-left: none;
  padding-left: 8px;
}

/*  */

/*  */

.document__container {
  padding: 36px 10px 5px 63px;
  display: flex;
  overflow: scroll;
}

.document__row {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.document__row__header {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
}

.document__row__icon:disabled {
  pointer-events: none;
}

.document__row__icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  border-radius: 4px;
}

.document__row__icon:hover {
  background: #f2f2f2;
}

.document__row__btn {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document__row__header:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.document__row__header img:hover {
  cursor: pointer;
}

.document__row__header img:last-child:hover {
  background-color: #ececec;
}

.document__row__header span {
  font-size: 14px;
  margin-left: 12px;
}

.document__row__container:last-child {
  margin-bottom: 10px;
}

.document__img {
  width: 300px;
  max-height: 400px;
  object-fit: contain;
}

.row__vertical__line {
  height: 400px;
  position: absolute;
  margin-left: 59px;
  margin-right: 59px;
  left: 330px;
  width: 1px;
  background-color: #c1c1c1;
}

.document__row__content {
  display: none;
  flex-direction: column;
}

.document__row__content__item {
  display: flex;
  align-items: center;
  margin-left: 38px;
  margin-top: 0px;
}

.document__row__content__item span {
  font-size: 14px;
  margin-right: 12px;
  color: #2f80ed;
  cursor: pointer;
}

.document__active {
  display: flex;
}

.span__active {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

/*  */

.tab__modal__settings {
  position: fixed;
  width: 590px;
  height: 595px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
}

.tab__settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.tab__btn__settings {
  padding-top: 21px;
  padding-left: 20px;
  height: 60px;
  overflow: hidden;
}

.tab__close__settings {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.tab__close__settings:hover {
  background-color: #ececec;
  cursor: pointer;
}

.tab__settings button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  width: 140px;
  height: 40px;
  transition: 0.3s;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #4f4f4f;
  border: 1px solid #efefef;
  border-radius: 7px 7px 0px 0px;
}

.tab__settings button:hover {
  background-color: #efefef;
}

.tab__settings button.active {
  border-bottom: 5px solid #828282;
}

/*  */

.checkbox-google {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  user-select: none;
}

.checkbox-google .checkbox-google-switch {
  display: inline-block;
  width: 46px;
  height: 16px;
  border-radius: 20px;
  position: relative;
  top: 6px;
  vertical-align: top;
  background: #dcdcdc;
  transition: 0.2s;
}

.checkbox-google .checkbox-google-switch:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -5px;
  left: -1px;
  background: #c4c4c4;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.15s;
}

.checkbox-google input[type="checkbox"] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-google input[type="checkbox"]:checked + .checkbox-google-switch {
  background: #aedec2;
}

.checkbox-google
  input[type="checkbox"]:checked
  + .checkbox-google-switch:before {
  background: #27ae60;
  transform: translateX(22px);
}

/* Hover */
.checkbox-google
  input[type="checkbox"]:not(:disabled)
  + .checkbox-google-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

/* Active/Focus */
.checkbox-google
  input[type="checkbox"]:not(:disabled):active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-on 0.5s forwards linear;
}

@keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:focus
  + .checkbox-google-switch:before {
  animation: checkbox-active-off 0.5s forwards linear;
}

@keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

/* Disabled */
.checkbox-google input[type="checkbox"]:disabled + .checkbox-google-switch {
  filter: grayscale(60%);
  border-color: rgba(0, 0, 0, 0.1);
}

.checkbox-google
  input[type="checkbox"]:disabled
  + .checkbox-google-switch:before {
  background: #eee;
}

/*  */

.settings__container {
  padding: 0 20px;
}

.settings__row {
  margin-top: 15px;
}

.settings__row__header {
  display: flex;
  justify-content: space-between;
}

.settings__row__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
}

.settings__row__content span {
  font-size: 12px;
}

/*  */

.settings__container__2 {
  padding: 26px 20px;
}

.settings__row__header__2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.settings__row__2 {
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
}

.settings__row__2__content {
  font-size: 13px;
  display: flex;
  /* justify-content: space-between; */
}

.content__left {
  margin-right: 2px;
}

.content__vertical {
  width: 1px;
  height: 333px;
  background-color: #e0e0e0;
}

.content__left__header {
  display: flex;
  flex-direction: column;
  width: 271px;
}

.header__row {
  height: 19px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  width: 235px;
}

.content__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  height: 18px;
}

.content__row__grey {
  background-color: #f2f2f2;
  width: 272px;
  height: 30px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content__row__grey span {
  font-size: 12px;
}

.content__row__grey span img {
  margin-left: 4px;
}

.content__row__grey span img:hover {
  cursor: pointer;
}

.content__right {
  display: flex;
  justify-content: center;
  padding-top: 11px;
  padding-left: 30px;
}

.content__right__name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  height: 305px;
  justify-content: space-between;
}

.content__right__value {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 305px;
  justify-content: space-between;
}

/*  */

.doc__modal__container {
  position: fixed;
  z-index: 990;
  top: 2.5%;
  left: 2.5%;
  background-color: #fff;
  width: 95%;
  height: 95%;
  overflow-y: hidden;
  padding: 20px 18px;
  border: solid 1px #8c8c8c;
}

.modal__header__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__header__title img {
  padding: 7px;
}

.modal__header__title img:hover {
  cursor: pointer;
  background-color: #ececec;
}

.modal__header__select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.modal__header__select__journal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.modal__header__button__journal {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.doc__modal__footer__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.doc__modal__footer__btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid #efefef;
  background-color: #fafafa;
  border-radius: 3px;
  width: 162px;
  height: 54px;
}

.doc__modal__table {
  padding: 2px;
  max-height: calc(100vh - 245px);
  min-height: 350px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.doc__modal__table table {
  margin-bottom: 0;
}

.buttonVacation {
  background: green;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: 400;
  font-size: 28px;
  border-radius: 100%;
  margin: 0 auto;
}

.doc__modal__table .react-datepicker__input-container input {
  padding: 0 8px;
  height: 24px;
}

.doc__modal__table thead {
  position: sticky;
  top: -3px;
  background: white;
  z-index: 1;
}

.doc__modal__table__wage {
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  height: 100%;
  padding: 2px 2px 0;
  margin: 0;
}

.doc__modal__table__wage table {
  height: max-content;
}

.doc__modal__table__wage thead {
  background: white;
  position: sticky;
  top: -3px;
  z-index: 1;
}

.journal__modal__table table {
  margin-bottom: 0;
}

.journal__modal__table.journal__modal__table--scroll {
  max-height: calc(100vh - 180px);
}

.journal__modal__table {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 2px;
  margin: 0 0 10px;
}

.journal__modal__table .hover-underline:hover {
  font-weight: bold;
  transition: 0.5s ease;
}

.journal__modal__table thead {
  position: sticky;
  top: -3px;
  background: white;
}

.doc__modal__footer__btn button:hover {
  background-color: #efefef;
  cursor: pointer;
}

.doc__modal__footer__btn button p {
  margin-left: 16px;
  font-size: 13px;
  font-weight: bold;
}

.doc__modal__zoom {
  position: fixed;
  z-index: 9999;
  left: 22%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 400px;
}

.doc__modal__zoom img {
  width: 120px;
  height: 120px;
}

.doc__modal__zoom img:hover {
  cursor: pointer;
}

/*  */
.doc__modal__table__wage .__react_component_tooltip {
  max-width: 340px;
}

.wage__table__pencil img:hover {
  background-color: #ececec;
  border-radius: 3px;
  cursor: pointer;
}

.doc__modal__footer__btn__wage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}

.doc__modal__footer__btn__wage button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid #efefef;
  background-color: #fafafa;
  border-radius: 3px;
  width: 162px;
  height: 54px;
}

.doc__modal__footer__btn__wage button:hover {
  background-color: #efefef;
  cursor: pointer;
}

.doc__modal__footer__btn__wage button p {
  margin-left: 16px;
  font-size: 13px;
  font-weight: bold;
}

.doc__modal__footer__btn__wage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.doc__modal__table__wage__total {
  padding: 0 40px;
  display: flex;
}

.doc__modal__table__wage__item {
  background-color: #fff;
  width: 73px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: absolute;
  bottom: -81px;

  right: 0;
}

.modal__footer__pay {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 92px;
  padding: 8px 9px;
  border: 2px solid #efefef;
  margin-left: 50px;
  margin-top: 35px;
}

.modal__footer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal__footer__header span {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.modal__footer__header button {
  width: 82px;
  height: 30px;
  background-color: #74b027;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.modal__footer__header button:hover {
  background-color: #8bc73d;
}

.modal__footer__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: inset 0px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 30px;
}

.modal__footer__content span {
  font-size: 12px;
  line-height: 14px;
}

/*  */

.workTime__modal__table {
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  padding: 2px;
  margin: 0;
}

.workTime__modal__table table > tr:nth-child(1) {
  position: sticky;
  top: -2px;
  background: white;
}

.workTime__modal__table table {
  border-collapse: collapse;
}

.workTime__modal__table table,
.workTime__modal__table table th,
.workTime__modal__table table td {
  border: 0.5px solid #d7d7d7;
}

.workTime__modal__table table tr td {
}

.table__lg {
  width: 236px;
  height: 40px;
  padding-left: 6px;
  font-size: 13px;
  line-height: 15px;
}

.table__sm {
  width: 35px;
  height: 40px;
  font-size: 13px;
  font-weight: 400;
}

.table__sm {
  text-align: center;
}

.table__sm:hover > .workTime__select {
  display: block;
}

.table__sm:hover > .table__workDay {
  display: none !important;
}

.table__day {
  color: #4f4f4f;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.workTime__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 2px 5px 3px;
  width: 100%;
  height: 100%;
  color: #000;
  border: 3px solid #2f80ed;
  border-radius: 4px;
  font-size: 11px;
  display: none;
  background: url(../../../../assets//downArrow.png) no-repeat bottom center
    #fff;
}

.addXmlFileButton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #e8f8ff;
}

.addXmlFileButton:hover {
  opacity: 0.8;
}

.jksTableArrowDown {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 2px 5px 3px;
  width: 100%;
  height: 100%;
  color: #000;
  border: 3px solid #2f80ed;
  border-radius: 4px;
  font-size: 11px;
  display: none;
  background: url(../../../../assets//downArrow.png) no-repeat bottom center
    #1c1b1f;
}
.modal__header__jksStatistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;
}
.modal__header__jksStatistics .jksStatisticsDocuments {
  display: flex;
  flex-direction: column;
}
.jksDocumentCounter {
  font-size: 13px;
  margin-top: 5px;
}
.jksState {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.jksState div:nth-child(2n) {
  margin-left: 10px;
}
.jksDatePicker {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 200px;
}
.jksDatePicker .jks_modal__header__select:nth-child(2n) {
  margin-left: 15px;
}
.jksTbody {
  position: relative;
}
.signReportSection {
  margin: 15px 15px;
  display: flex;
  justify-content: space-between;
}
.signReportButton {
  margin-top: 15px;
  border-radius: 8px;
  background: linear-gradient(90deg, #b000b4 0%, #00b4b4 98.23%);
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.signReportDelete {
  position: absolute;
  right: 16px;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.06);
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: #e40303;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.signReportButton:disabled {
  opacity: 0.3;
}

.pagination_item:nth-child(2n) {
  margin: 13px 14px;
}
.pagination_item {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

/*  */

.add__modal_container {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 80%;
  height: 90%;
  padding: 23px 35px;
  border: solid 1px #8c8c8c;
}

.add__modal_container input,
.add__modal_container .text__table__content,
.add__modal_container textarea {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #313131;
}

.add__modal_container textarea {
  padding: 8px;
  height: 34px;
  min-height: 34px;
}

.add__modal_container .table__container__scroll {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.add__modal_container textarea {
  padding: 8px;
  height: 34px;
  min-height: 34px;
}

.add__modal_container textarea#description {
  min-height: 94px;
}

.add__modal_container input::placeholder {
  color: #c9c9c9;
  font-size: 13px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal__header img {
  padding: 7px;
  border-radius: 3px;
}

.modal__header img:hover {
  background-color: #ececec;
  cursor: pointer;
}

.modal__header__left {
  margin-top: 20px;
  display: flex;
}

.modal__header__content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.header__content__item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.header__content__text {
  font-size: 13px;
  font-weight: normal;
  border: 1px solid #e3e3e3;
  padding: 2px 0px 20px 6px;
  border-radius: 4px;
}

.text__table__left {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-size: 13px;
  line-height: 15px;
  border: 1px solid #e3e3e3;
}

.header__content__text__table {
  display: flex;
}

.text__table__lg {
  height: 89px;
}

.text__table__sm {
  height: 34px;
}

.text__table__content {
  display: flex;
  padding-left: 3px;
  align-items: center;
  width: 863px;
  border: 1px solid #e3e3e3;
  font-size: 13px;
  resize: vertical;
  outline: none;
  min-height: 35px;
  font-family: Roboto;
  font-weight: 400;
  color: #313131;
  height: auto;
}

.text__table__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-left: none;
}

.text__table__right:hover {
  background-color: #e3e3e3;
  cursor: pointer;
}

.modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.modal__footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  background-color: #f2f2f2;
  border: none;
}

.modal__footer button:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}

.modal__footer button img {
  transform: rotate(45deg);
}

.modal__footer__green {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.search-input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s ease;
  position: relative;
  height: 23px;
  width: 42px;
  padding: 2px 5px 0 2px;
  color: #000;
}

.search-input:focus {
  border: 3px solid #56ccf2;
}

.table__choose {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.table__chooseItem {
  height: 28px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 0 8px;
}

.table__chooseItem.selected {
  background: #009640;
  color: white;
}

.disabled__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #009640;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009640 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hover-underline:hover {
  text-decoration: underline;
}
