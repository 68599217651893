.scanner h3 {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 2px;
    margin-top: 20px;
    color: #3fefef;
    filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
    animation: animate_text 0.5s steps(1) infinite;
}

.scanner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.scanner .fingarprint {
    position: relative;
    width: 300px;
    height: 380px;
    background: url(./assets/img/image1.png);
    background-size: 300px;
}

.scanner .fingarprint::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./assets/img/image2.png);
    background-size: 300px;
    animation: animate 4s ease-in-out infinite;
}

.scanner .fingarprint::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: #3fefef;
    border-radius: 8px;
    filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
    animation: animate_line 4s ease-in-out infinite;
}

@keyframes animate {
    0%, 100% {
        height: 0;
    }

    50% {
        height: 100%;
    }
}

@keyframes animate_line {
    0%,
    100% {
        top: 0;
    }
    50% {
        top: 100%;
    }
}

@keyframes animate_text {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}