.TableRow {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: top;
    color: #313131;
    border-top: 2px rgba(0,0,0,0) solid;
    border-left: 2px rgba(0,0,0,0) solid;
    border-right: 2px rgba(0,0,0,0) solid;
    border-bottom: 1px #f2f2f2 solid;
    box-sizing: border-box;
    padding: 5px 0;
    cursor: pointer;
}
.TableRow_active {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: top;
    color: #313131;
    background: #DFFAF0;
    box-sizing: border-box;
    border: 2px solid #59E4B1;    
    padding: 5px 0;
    cursor: pointer;
}
.Table__employer:hover {
    background-color: transparent !important;
}
.TableRow_new {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: flex-start;
    color: #313131;
    background: #FFFFEA;
    box-sizing: border-box;
    border: 2px solid #F2C94C;    
    padding: 5px 0;
    cursor: pointer;
}

.TableRow:hover {
    background-color: #f2f2f2;
}
/* .TableRow:focus {
    background-color: #DFFAF0;
} */

.TableHeader {
    padding: 5px 0;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    color: #313131;
    border-bottom: 1px #f2f2f2 solid;
}