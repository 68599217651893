.conditions__workdoc {
    background-color: #fff;
    padding: 6px 28px 38px;
    position: fixed;
    z-index: 9999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.conditions__workdoc .react-datepicker__input-container input{
    padding: 0 14px;
    max-width: 143px;
}
.conditions__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: black;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 7px;
}

.conditions__content {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 0;
    margin: 17px 0 33px;
}

.conditions__content-row {
    display: flex;
    align-items: center;
}

.conditions__content-left {
    flex: 0 0 230px;
    display: flex;
    flex-direction: column;
}

.conditions__content-right {
    flex: 1 0 auto;
}

.conditions__content-left p {
    font-size: 13px;
    line-height: 15px;
    padding: 10px 0;
    height: 34px;
    margin: 0 0 6px 0;
}

.conditions__content-left p:last-of-type {
    margin: 0;
}

.conditions__row {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 6px;
}

.conditions__row .error {
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    font-size: 11px;
    color: red;
    z-index: 1;
}

.conditions__row:last-of-type {
    margin-bottom: 0;
}

.conditions__row svg {
    position: absolute;
}

.conditions__row p {
    color: #009640;
    padding: 0 12px;
}

.conditions__form {
    flex-direction: column;
}

.conditions__buttons {
    display: flex;
    justify-content: center;
    position: relative;
    width: max-content;
}

.conditions__buttons .lds-spinner {
    position: absolute;
    left: 10px;
    top: 7px;
    color: white;
}

.conditions__buttons .lds-spinner > div:after {
    background: white;
}

.conditions__clock-icon--1 {
    left: 115px;
}

.conditions__clock-icon--2 {
    right: 0px;
}

.conditions__input-button {
    width: 44px;
    height: 34px;
    border-width: 1px 1px 1px 0;
    border-color: #c1c1c1;
    border-style: solid;
    border-radius: 0 4px 4px 0;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conditions__input-button img {
    width: 22px;
}

.conditions__calendar {
    display: flex;
    position: relative;
}

.conditions__calendar .item__input__check {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 1px solid rgba(193, 193, 193, 0.8);
    max-width: 100px;
}

.conditions__calendar .react-datepicker-wrapper {
    max-width: 155px;
}

.conditions__time-input {
    padding: 8px 8px;
    font-size: 13px;
    line-height: 15px;
    width: 94px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    margin-bottom: 1px;
}

/* .conditions__content input, .conditions__content select {
    font-size: 13px;
    line-height: 15px;
    padding-left: 11px;
    height: 34px;
    border: 1px solid #c1c1c1;
} */

/* .conditions__content input::placeholder {
    color: #bfb5bd;
    font-size: 14px;
    padding-left: 12px;
} */

.conditions__select-input {
    width: 145px;
    height: 34px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    color: #313131;
    outline: none;
    word-wrap: normal !important;
    padding-left: 4px;
}

.conditions__text-input {
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    color: #bfb5bd;
}

.conditions__text-input_full-width {
    width: 100%
}

.doc-viewer {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #4F4F4F;
    transform: unset;
    text-align: center;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 0;
}

.doc-viewer__close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 120;
}

.doc-viewer__close img {
    transition: 0.3s ease;
}

.doc-viewer__close:hover img {
    filter: brightness(0) invert(1);
}
