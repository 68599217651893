/* Стили контейнера */

.kadri__item__row {
    display: flex;
    align-items: baseline;
    margin-bottom: 26px;
}

.kadri__item__row:last-child {
    margin-bottom: 0;
}

#create .__react_component_tooltip {
    max-width: 270px;
    padding: 5px;
    text-align: center;
}

.back__btn {
    display: flex;
    align-items: center;
}

.back__btn img {
    margin-right: 20px;
}

.table__row__del .TableHeader span {
    font-weight: 500;
    margin-left: 5px;
}

.table__row__del .TableHeader {
    font-size: 12px;
    font-weight: 300;
    background: #fbfbfb;
    cursor: inherit !important;
}
.dropdown__works{
        margin-left: 8px;
}
.doc__add__wrapper{
    position: relative;
}
.doc__add__wrapper .error{
    position: absolute;
    bottom: 0;
    color: red;
    font-size: 10px;
    transform: translate(0,100%);
}
.table__container__scroll .error{
    color: red;
    font-size: 10px;
    margin-left: 8px;
}
.dropdown__works .table__dropdown__content{
    padding: 0;
}
.dropdown__works>button{
    font-size: 12px;
    color: black;
}
.add__modal_container  .table__value__item span{
     display: flex;
    align-items: center;
}
.dropdown__works .submenu__dropdown__btn{
    font-size: 12px;
}
.dropdown__works .submenu__dropdown__btn span{
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: black;
}
.label {
    position: relative;
}

.label:before {
    content: "";
    position: absolute;
    left: -8px;
    width: 10px;
    border-radius: 100%;
    height: 10px;
    top: 50%;
    transform: translate(-100%, -50%);
}

.label.label--green:before {
    background: green;
}

.label.label--red:before {
    background: #d92727;
}

.label.label--orange:before {
    background: orange;
}

.label.label--gray:before {
    background: gray;
}


[data-active="active"] {
    -webkit-animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation-delay: 1s;
}

@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}


.submenu_back {
    border-top: 1px solid #c9c9c9;
    padding-top: 6px;
}

.submenu_back > .submenu__dropdown__btn:hover {
    background: #fee5e5;
}

.submenu_back > .submenu__dropdown__btn {
    color: #bc2c2c;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 4px 16px;
}

.back__btn span {
    color: #009640;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
}

.back__btn:hover {
    background-color: #f8f8f8;
}

.checkbox__deletedList *,
.checkbox__deletedList {
    cursor: pointer;
}

.checkbox__deletedList {
    display: flex;
    align-items: center;
}

/* DropDown стили  */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 38px;
    background-color: #fff;
    width: 160px;
    height: 103px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content button {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 22px;
    color: black;
    height: 26px;
    font-size: 13px;
    font-family: Roboto;
    text-decoration: none;
    display: block;
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.dropdown-content button:first-child {
    margin-top: 8px;
}

.dropdown-content button:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

/* Затемнение фона */

.bg__layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 134%;
}

.right-0 {
    right: 0 !important;
}
