
.Input__text {
    width: 100%
}

.Input__button {
    width: 50px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: #FFDC64;
    transition: 0.2s ease;
    margin-left: 1px;
  }
  .Input__button:hover {
    background: #F2C94C;
  }
  .Input__button_disabled {
    background: #FAFAFA;
    border: 2px solid #EFEFEF;
  }
  .Input__button_disabled svg {
    opacity: 0.6;
  }
  .Input__button_disabled:hover {
    background: #FAFAFA;
  }