.SearchInput {
    position: relative;
    padding-left: 32px;
}

.SearchInput__icon {
    position: absolute;
    left: 46px;
    top: 12px;
    cursor: pointer;
}