.EmployersInfo__content {
    margin: 22px 0 0 0;
    display: grid;
    grid-template-columns: 110fr 314fr;
    grid-gap: 10px 10px;
    gap: 10px 10px;
}

.EmployersInfo__text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.EmployersInfo__text-container .EmployersInfo__text {
    margin-bottom: 3px;
}
.EmployersInfo__text-container .EmployersInfo__text:last-of-type {
    margin-bottom: 0;
}

.EmployersInfo__date {
    margin-right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    align-self: center;
    color: #313131;
}
