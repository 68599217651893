.google-places {
    position: relative;
    width: 100%;
}

.google-places input {
    width: 100%;
}

.google-places input.error_border {
    border: 1px solid red;
}

.google-places__input {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.google-places__input:not(:first-child) {
    margin-left: 5px;
}

.street {
    margin-left: 5px;
}

.google-places .error {
    position: absolute;
    bottom: 0;
    color: red;
    font-size: 12px;
    transform: translate(0, 100%);
}

.google-places__list > li:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.google-places__list > li {
    padding: 5px 20px;
    font-size: 13px;
    line-height: 16px;
}

.google-places__list {
    display: none;
}

.google-places__list.active {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    width: 100%;
    background: white;
    list-style: none;
    transform: translate(0, 100%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    max-height: 150px;
    overflow-y: auto;
}