.TableNote {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}

.TableNote div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.TableNote p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #313131;
    text-align: right;
}