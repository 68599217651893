.AddEmployerModal {
    position: absolute;
    right: 0;
    padding: 10px 54px 10px 17px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 399px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.AddEmployerModal__form {
    display: flex;
    align-items: center;
}

.AddEmployerModal__error {
    color: red;
    font-size: 12px;
}