.input__wrapper {
  position: relative;
}

.staff-search {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 17px;
  font-size: 14px;
  line-height: 16px;
  color: #535353;
  padding-left: 42px;
  width: 256px;
  height: 36px;
  transition: 0.2s ease;
}
.staff-search:hover {
  border-color: #a7a7a7
}

.staff-search:focus {
  border: 3px solid #56ccf2;
}

.staff-search-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  cursor: pointer;
}

.staff-close-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: none;
}

.staff-close-btn:hover {
  background-color: #ececec;
}
.staff-close-btn{
  display: none;
}
.staff_show{
  display: block;
}
.staff_show:hover{
  cursor: pointer;
}

.staff-close-btn:hover .staff-close-icon {
  filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
}

.kadri__modals {
  display: flex;
  align-items: center;
}
.kadri__modals__active {
  display: block;
}
.bg__layer {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 104%;
  backdrop-filter: blur(4px);
}
table{
  border-spacing: 0.5;
}
.table__item__line:hover {
  background-color: #f2f2f2;
  border: 2px solid #59e4b1;
  cursor: pointer;
}
.table__item__td {
  padding: 0 8px;
  border-bottom: 1px #f2f2f2 solid;
  color: #535353;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
}
.table__item__td:first-child {
  padding: 0 8px 0 16px;
}
.table__item__td:last-child {
  padding-left: 16px;
  padding-right: 8px;
}
.selected {
  background: #DFFAF0;
}
.selected:hover {
  background: #DFFAF0;
}
.table__item__td {
  border: 2px solid transparent;
  border-bottom: 1px solid #f2f2f2;
}
.selected .table__item__td,
.selected:hover .table__item__td {
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
}
.selected:hover .table__item__td:first-child {
  cursor: pointer;
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
  border-left: 2px solid #59e4b1;
}
.selected:hover .table__item__td:last-child {
  cursor: pointer;
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
  border-right: 2px solid #59e4b1;
}
.table__item__line:hover .table__item__td__svg svg{
  margin-right: -2px;
}



.table__item__line__grey:hover {
  background-color: #dffaf0;
  border-bottom: 1px #f2f2f2 solid;
}
.table__item__line__grey:hover .table__item__td {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;

}
.table__item__line__grey:hover .table__item__td:first-child {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  border-left: 2px solid #f2f2f2;
  padding: 0 23px;
}
.table__item__line__grey:hover .table__item__td:last-child {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
  padding: 0 23px;
}
.table__item__line__grey:hover .table__item__td__svg svg{
  margin-right: -2px;
}
