.edit_personal {
    padding: 0 39px 39px;
    overflow: inherit;
}

.edit_personal__col {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.edit_personal__col input {
    width: 100%;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    height: 34px;
}

.edit_personal__col .edit_personal__input {
    flex: 1;
    margin-right: 16px;
}
.edit_personal__col .edit_personal__input:last-child{
    margin-right: 0;
}
.edit_personal__input label{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
    color: #535353;
}
.edit_personal__col .edit_personal__input-address {
    flex: 100%;
    max-width: 466px;
}
.edit_personal__col .edit_personal__input-address input {
    width: 100%;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    height: 34px;
}
.edit_personal__col .edit_personal__input-address span{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
    color: #535353;
}

.edit_personal__row {
    display: flex;
}

.edit_personal .Modal__header {
    background: #4CAF50;
    height: 50px;
    margin: 0 -39px 36px;
    padding: 0 39px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.edit_personal__row:not(:last-child) {
    margin-bottom: 40px;
}
.personal__btn_grey.disabled{
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    border:1px solid rgb(242, 242, 242);
}

.edit_personal__row span {
    color: #535353;
    flex-shrink: 0;
    width: 100%;
    max-width: 160px;
    margin-right: 54px;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}