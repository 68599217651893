.DropdownHint {
    position: absolute;
    bottom: -1px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    background: #fff;
    width: 100%;
    z-index: 2;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    list-style: none;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}
.DropdownHint[data-active="true"]{
    border: none;
}
