.personal {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1458px) {
    .personal {
        display: flex;
        flex-direction: column;
    }

    .personal__left {
        width: 100%;
    }
}

.personal__right table .table__icon {
    display: flex;
    justify-content: center;
}

.personal__right table thead {
    border-bottom: 1px #f2f2f2 solid;
    display: block;
}

.personal__right .table-row {
    display: grid;
    grid-template-columns: 0.5fr 7fr 5fr 6fr 100px 2fr;
    grid-gap: 8px;
    align-items: center;
    padding: 10px 20px;
}

.personal__right tbody .table-row.active,
.table__row__del > .table-row.active {
    background-color: rgb(223, 250, 240);
    border: 1px solid rgb(89, 228, 177);
}

.table__row__del > .table-row.active:hover,
.personal__right tbody .table-row.active:hover {
    background-color: rgb(223, 250, 240);
    border: 1px solid rgb(89, 228, 177);
}

.table__row__del > .table-row:hover,
.personal__right tbody .table-row:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.personal__right tr:not(:last-child) {
    border-bottom: 1px #f2f2f2 solid;
}

.personal__right .table-row th,
.personal__right .table-row tr,
.personal__right .table-row td {
    padding: 0;
    border: none;
}

.personal__right .table__row__del {
    border-top: 1px #f2f2f2 solid;
}

.personal__right .table__row__del .TableHeader {
    display: flex;
    background: white;
    justify-content: space-between;
    padding-right: 20px;
}

.personal__left,
.personal__right {
    flex: 1;
}

.personal__left {
    max-width: 35%;
    margin-right: 10px;
}

.personal__left .table thead {
    border-bottom: 1px #f2f2f2 solid;
    display: block;
}

.personal__left .table tbody tr:not(:last-child) {
    border-bottom: 1px #f2f2f2 solid;
}

.personal__left .table .table-row th,
.personal__left .table .table-row td {
    padding: 0;
    display: flex;
    border: none;
}

.personal__left .table .table-row {
    display: grid;
    grid-template-columns: 18px 30px 3fr 1fr 1fr;
    padding: 10px 20px;
    align-items: center;
    grid-gap: 8px;
}

.personal__right tbody .table-row .table__icon button {
    margin: 0 auto;
}

.personal__left tbody .table-row .table__icon button {
    margin: 0 auto;
}

.personal__title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #938686;
}

.personal__btn_grey {
    padding: 10px 20px;
    border: 1px solid #938686;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #938686;
    border-radius: 3px;
    background: #ffffff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.disabled-btn:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    cursor: inherit;
}

.personal__btn_grey:hover {
    border: 1px solid #009640;
    background: #fff;
    color: #009640;
}

.personal__btn_grey:active {
    background-color: #27ae60;
    color: #fff;
}

.table-row td input[type="checkbox"]:checked {
    background: #e0f3ec;
}
