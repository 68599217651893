@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  background: #f8f8f8;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

button,
input {
  outline: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  position: relative;
}

.react-viewer-transition {
  transition: none !important;
}

.root {
  overflow: hidden;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
  align-items: flex-end;
}

.content {
  width: 100%;
  max-width: 1600px;
  min-height: 86vh;
  margin: 0 auto;
  padding: 0 25px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.table {
  margin: 0px 0 13px 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.table-row th {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
}

.table-row td {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

[aria-live="polite"] br {
  display: none;
}

[aria-live="polite"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.table-row th,
.table-row td {
  border-bottom: 1px #f2f2f2 solid;
  padding: 10px;
  text-align: left;
  color: #313131;
}

.table-row th:first-child,
.table-row td:first-child,
.table-row th:last-child,
.table-row td:last-child {
  padding: 10px 20px;
}

.react-viewer-toolbar li,
.react-viewer ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row:last-child td {
  border: none;
}

.doc__modal__table__wage .table__icon__wrapper {
  display: flex;
  align-items: center;
}
.doc__modal__table__wage .table__icon__wrapper img {
  cursor: pointer;
  margin-left: 4px;
}

.table__icon {
  text-align: center !important;
  position: relative;
}

.table-icon {
  border-radius: 3px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.table-icon:hover {
  background: #ececec;
}

.green__checkbox:hover {
  border: 1.5px solid #009640 !important;
}

.green__checkbox:checked {
  background: #009640;
}

.green__checkbox {
  border: 1.5px solid #bfb5bd !important;
  box-sizing: border-box;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.__react-alert__ {
  z-index: 9999;
  position: relative;
}

@media screen and (max-width: 1549px) {
  .table {
    width: 100%;
  }
}

@media screen and (max-width: 811px) {
  .content {
    overflow-x: scroll;
  }
}

.react-datepicker-popper {
  z-index: 3 !important;
}

/* buttons */
.personal__btn {
  height: 36px;
  padding: 7px 20px;
  border: none;
  background: #009640;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

.edit__wrapper {
  position: relative;
}

.edit__wrapper .error {
  color: red;
  font-size: 11px;
  position: absolute;
}

.personal__btn:hover {
  background: #27ae60;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* main input class */
.inputForm {
  width: calc(100% - 4px);
  height: 32px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 0 0 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  box-sizing: border-box;
  color: black;
  transition: 0.2s ease;
}

.inputForm.error {
  border: 1px solid red;
}

.inputForm:hover {
  border: 1px solid #a4a4a4;
}

.inputForm::placeholder {
  color: #bfb5bd;
}

.pos-rel {
  position: relative;
}

/* scrollbar */
::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background: #bfc4ca;
  border-radius: 2px;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
  max-height: 90vh !important;
  max-width: 90vw !important;
}

/*.pg-viewer-wrapper .pg-viewer {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/
.pictureViewer {
  width: 100% !important;
  height: 100% !important;
}

.tab__modal.modalPassport {
  width: 100%;
  height: inherit;
}

.tab__modal.modalPassport .table__container__full,
.tab__modal.modalPassport .tab,
.tab__modal.modalPassport form {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
