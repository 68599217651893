@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  background: #f8f8f8;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

button,
input {
  outline: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  position: relative;
}

.react-viewer-transition {
  transition: none !important;
}

.root {
  overflow: hidden;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0;
  align-items: flex-end;
}

.content {
  width: 100%;
  max-width: 1600px;
  min-height: 86vh;
  margin: 0 auto;
  padding: 0 25px;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.table {
  margin: 0px 0 13px 0;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.table-row th {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
}

.table-row td {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
}

[aria-live="polite"] br {
  display: none;
}

[aria-live="polite"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.table-row th,
.table-row td {
  border-bottom: 1px #f2f2f2 solid;
  padding: 10px;
  text-align: left;
  color: #313131;
}

.table-row th:first-child,
.table-row td:first-child,
.table-row th:last-child,
.table-row td:last-child {
  padding: 10px 20px;
}

.react-viewer-toolbar li,
.react-viewer ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row:last-child td {
  border: none;
}

.doc__modal__table__wage .table__icon__wrapper {
  display: flex;
  align-items: center;
}
.doc__modal__table__wage .table__icon__wrapper img {
  cursor: pointer;
  margin-left: 4px;
}

.table__icon {
  text-align: center !important;
  position: relative;
}

.table-icon {
  border-radius: 3px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.table-icon:hover {
  background: #ececec;
}

.green__checkbox:hover {
  border: 1.5px solid #009640 !important;
}

.green__checkbox:checked {
  background: #009640;
}

.green__checkbox {
  border: 1.5px solid #bfb5bd !important;
  box-sizing: border-box;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.__react-alert__ {
  z-index: 9999;
  position: relative;
}

@media screen and (max-width: 1549px) {
  .table {
    width: 100%;
  }
}

@media screen and (max-width: 811px) {
  .content {
    overflow-x: scroll;
  }
}

.react-datepicker-popper {
  z-index: 3 !important;
}

/* buttons */
.personal__btn {
  height: 36px;
  padding: 7px 20px;
  border: none;
  background: #009640;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

.edit__wrapper {
  position: relative;
}

.edit__wrapper .error {
  color: red;
  font-size: 11px;
  position: absolute;
}

.personal__btn:hover {
  background: #27ae60;
}

.modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* main input class */
.inputForm {
  width: calc(100% - 4px);
  height: 32px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 0 0 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  box-sizing: border-box;
  color: black;
  transition: 0.2s ease;
}

.inputForm.error {
  border: 1px solid red;
}

.inputForm:hover {
  border: 1px solid #a4a4a4;
}

.inputForm::-webkit-input-placeholder {
  color: #bfb5bd;
}

.inputForm::placeholder {
  color: #bfb5bd;
}

.pos-rel {
  position: relative;
}

/* scrollbar */
::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background: #bfc4ca;
  border-radius: 2px;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
  max-height: 90vh !important;
  max-width: 90vw !important;
}

/*.pg-viewer-wrapper .pg-viewer {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/
.pictureViewer {
  width: 100% !important;
  height: 100% !important;
}

.tab__modal.modalPassport {
  width: 100%;
  height: inherit;
}

.tab__modal.modalPassport .table__container__full,
.tab__modal.modalPassport .tab,
.tab__modal.modalPassport form {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.login {
  display: flex;
  height: 100vh;
  background: #ffffff;
}
.login__left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.login__logo {
  max-width: 440px;
  height: 100px;
  margin: -100px 50px 0;
}
.login__logo img {
  width: 100%;
  /* height: 68%; */
}

.login__right {
  flex: 2 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #109e52;
}
.login__form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__title {
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 20px;
}
.login__input {
  width: 320px;
  height: 50px;
  margin-bottom: 2px;
  border: none;
  padding: 15px 0 15px 23px;
  border-radius: 3px;
  outline: none;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}
.login__input_error {
  border: 3px solid red
}
.login__input::-webkit-input-placeholder {
  color: #a59696;
}
.login__input::placeholder {
  color: #a59696;
}
.login__error {
  width: 320px;
  height: 15px;
  margin-bottom: 5px;
  text-align: left;
  color: red;
  font-size: 12px;
}
.login__btn {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: bold;
  font-size: 22px;
  border-radius: 3px;
  padding: 12px 30px;
  background: #ffff;
}
.login__btn a {
  color: #109e52;
  text-decoration: none;
}

.MiniButton {
    width: 28px;
    height: 28px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: .2s ease;
    cursor: pointer;
    z-index: 0;
}

.MiniButton:hover {
    background-color: rgba(0,0,0,.1);
    transition: .2s ease
}
.MiniButton:focus + .Dropdown__menu,
.Dropdown__menu:hover{
    display: flex !important;
}

.MiniButton_disabled {
    opacity: .6;
    cursor: not-allowed;
}

.MiniButton_disabled:hover {
    background-color: rgba(0,0,0,0);
}

.Modal {
    max-height: 85vh;
    background-color: #fff;
    padding: 6px 28px 38px;
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1000px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
}
.Modal.transfer{
    overflow-y: inherit;
}
.Modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: black;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 4px;
}

.Modal__content {
    margin: 22px 0 0 0;
    display: grid;
    grid-template-columns: 210fr 314fr;
    grid-gap: 6px 20px;
    gap: 6px 20px;
}

.Modal__text {
    align-self: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.Modal__code {
    align-self: center;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #313131;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}

.Modal__button-row {
    justify-content: center;
    margin-top: 26px;
    width: 100%;
    display: flex;
}
.Modal__button-link {
    text-decoration: none;
}

.home {
  height: 100%;
}

.header {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 0 calc((100% - 1550px) / 2);
  box-sizing: border-box;
  grid-template-areas: 
    "header__logo header__links header__user"; 
}

@media (max-width: 1600px) {
  .header {
    padding: 0 25px
  }
}

/* LOGO */


/* Links */
.header__logo {
  grid-area: header__logo;
  justify-self: left;
  align-self: center;
}

.header__links {
  grid-area: header__links;
  justify-self: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.header__links ul {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__links ul li {
  height: 100%;
  list-style: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.header__links ul li a {
  color: #bbbbbb;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
}

.header__links ul li a:hover {
  color: #009640;
  background-color: #eeeeee;
}

.header__links ul li .header__links_active {
  color: #009640;
  border-bottom: 3px #27ae60 solid;
  border-radius: 4px 4px 0px 0px;
  background: rgba(0, 150, 64, 0.1);
}

/* USER */

.header__user {
  grid-area: header__user;
  justify-self: right;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.header__user-img svg path {
  transition: all 0.2s ease-in-out;
}
.header__user:hover .header__user-name {
  color: #009640;
}
.header__user:hover .header__user-img svg path {
  fill: #009640;
}
.header__user-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #938686;
  margin-right: 28px;
  transition: all 0.2s ease-in-out;
}
.header__user:focus .header__drop,
.header__drop:hover {
  display: flex;
}
.header__user-name:hover {
  color: #009640;
}
.header__user-name:hover + .header__user-img svg path {
  fill: #009640;
}
.header__drop {
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  width: 100%;
  padding: 5px 0;
  display: none;
  flex-direction: column;
  z-index: 100;
  align-items: flex-start;
  transition: 0.2s ease;
  box-shadow: 0px 0px 5px #ccc;
}
.header__drop .header__dropItem {
  padding: 4px 12px;
  color: #009640;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  transition: 0.2s ease;
  text-align: left;
  text-decoration: none;
}
.header__drop .header__dropItem:hover {
  background-color: #eeeeee;
}
.header__drop .header__dropItem:first-child {
  margin-bottom: 5px;
}

.StartCard {
    /* width: 100%;
    height: auto; */
    position: relative;
    background: #FFFFFF;
    border: 1px solid #DBE7EF;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 20px 27px 20px;
    /* margin-bottom: 50px; */
}

.StartCard__button-row {
    display: flex;
}
.Start {
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 0 0;
    box-sizing: border-box;
    display: grid; 
    grid-template-columns: 460px 460px 460px; 
    grid-gap: 58px 85px; 
    gap: 58px 85px;
    align-items: start;
    justify-content: left;
}

@media(max-width: 1600px) {
    .Start {
        grid-gap: 40px 40px;
        gap: 40px 40px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.TextInput {
    
}
.Input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 15px;
    box-sizing: content-box;
    color: #535353;
    /* transition: .2s linear; */
    margin: 2px; 
    padding-left: 12px
}
.Input:hover {
    border: 1px solid #A4A4A4;
}
.Input:focus {
    border: 3px solid #56CCF2;
    margin: 0;
}
.Input::-webkit-input-placeholder {
    color: #BFB5BD
}
.Input::placeholder {
    color: #BFB5BD
}

.edit_user__modal {
    padding: 0;
}
.edit_user__modal > .Modal__header {
    background-color: #009640;
    color:  white;
    font-size: 16px;
    margin-bottom:  0;
    padding:  10px 20px;
    margin-top: 0px;
}
.edit_user__modal > .edit_user__form {
    padding: 12px 20px;
}
.edit_user__form {
    display: flex;
    flex-direction: column;
}
.edit_user__row {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    margin-top: 8px;
    text-align: left;
    position: relative;
}
.edit_user__row > label {
    display:  inline-block;
    width: 33.3%;
}
.edit_user__row > input,
.edit_user__row > select {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    text-align: left;
    width: 66.6%;
}

.edit_user__row > input.telephone {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 32px;
    padding: 10px 17px;
    font-size: 13px;
    line-height: 16px;
    color: black;
    transition: 0.2s ease;
}

.edit_user__row p {
    position: absolute;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    right: 0;
    bottom: 12px;
    color: red;
    font-size: 10px;
}

.edit_user__row > input.error {
    border: 1px solid red;
}

.edit_user__roles {
    width: 66.6%;
    float: right;
}

.edit_user__roles > label > input {
    line-height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}
.edit_user__roles > label {
    display:  inline-block;
    line-height: 16px;
    min-width: 50%;
    -webkit-user-select: none;
            user-select: none;
    white-space:  nowrap;
}
.edit_user__footer {
    margin-bottom:  10px;
    margin-top: 26px;
    text-align:  right;
}


.Input__text {
    width: 100%
}

.Input__button {
    width: 50px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: #FFDC64;
    transition: 0.2s ease;
    margin-left: 1px;
  }
  .Input__button:hover {
    background: #F2C94C;
  }
  .Input__button_disabled {
    background: #FAFAFA;
    border: 2px solid #EFEFEF;
  }
  .Input__button_disabled svg {
    opacity: 0.6;
  }
  .Input__button_disabled:hover {
    background: #FAFAFA;
  }
.SearchInput {
    position: relative;
    padding-left: 32px;
}

.SearchInput__icon {
    position: absolute;
    left: 46px;
    top: 12px;
    cursor: pointer;
}
.Table {
    margin: 26px 0;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
}
.TableCell {
    margin: 7px 0;
    word-break: break-all;
    display: flex;
    align-items: center;
}

.TableRow {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: top;
    color: #313131;
    border-top: 2px rgba(0,0,0,0) solid;
    border-left: 2px rgba(0,0,0,0) solid;
    border-right: 2px rgba(0,0,0,0) solid;
    border-bottom: 1px #f2f2f2 solid;
    box-sizing: border-box;
    padding: 5px 0;
    cursor: pointer;
}
.TableRow_active {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: top;
    color: #313131;
    background: #DFFAF0;
    box-sizing: border-box;
    border: 2px solid #59E4B1;    
    padding: 5px 0;
    cursor: pointer;
}
.Table__employer:hover {
    background-color: transparent !important;
}
.TableRow_new {
    min-height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: flex-start;
    color: #313131;
    background: #FFFFEA;
    box-sizing: border-box;
    border: 2px solid #F2C94C;    
    padding: 5px 0;
    cursor: pointer;
}

.TableRow:hover {
    background-color: #f2f2f2;
}
/* .TableRow:focus {
    background-color: #DFFAF0;
} */

.TableHeader {
    padding: 5px 0;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    display: grid;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    color: #313131;
    border-bottom: 1px #f2f2f2 solid;
}
.EmployersInfo__content {
    margin: 22px 0 0 0;
    display: grid;
    grid-template-columns: 110fr 314fr;
    grid-gap: 10px 10px;
    grid-gap: 10px 10px;
    gap: 10px 10px;
}

.EmployersInfo__text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.EmployersInfo__text-container .EmployersInfo__text {
    margin-bottom: 3px;
}
.EmployersInfo__text-container .EmployersInfo__text:last-of-type {
    margin-bottom: 0;
}

.EmployersInfo__date {
    margin-right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    align-self: center;
    color: #313131;
}

.AddEmployerModal {
    position: absolute;
    right: 0;
    padding: 10px 54px 10px 17px;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 399px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.AddEmployerModal__form {
    display: flex;
    align-items: center;
}

.AddEmployerModal__error {
    color: red;
    font-size: 12px;
}
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
  }
  .lds-spinner div {
    -webkit-transform-origin: center 11px;
            transform-origin: center 11px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
            animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: 10px;
    width: 2px;
    height: 6px;
    border-radius: 20%;
    background: green;
  }
  .lds-spinner div:nth-child(1) {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg);
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    -webkit-transform: rotate(300deg);
            transform: rotate(300deg);
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    -webkit-transform: rotate(330deg);
            transform: rotate(330deg);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  @-webkit-keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
.Dropdown {
    position: relative
}

.Dropdown__menu {
    position: absolute;
    right: 0px;
    z-index: 9999;
    display: none;
    flex-direction: column;
    /* min-width: 140px; */
    width: 150px;
    background: #FFFFFF;
    border: 1px solid #D7D7D7;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
}

.Dropdown__menu_disabled {
    display: none
}

/* Таблица */

.table {
    background-color: #fff;
}

.table__dropdown__content {
    border: 1px solid #d7d7d7;
    display: none;
    position: absolute;
    background-color: #fff;
    width: -webkit-max-content;
    width: max-content;
    white-space: nowrap;
    min-width: 150px;
    /*height: 164px;*/
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 9px 0;
}

.edit_rent-document__right .table__dropdown__content .submenu__dropdown__btn {
    white-space: normal;
}

.table__dropdown__content:hover {
    display: block;
}

.table__dropdown__content a {
    color: black;
    padding: 7.5px 26px;
    text-decoration: none;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}

/* Change color of dropdown links on hover */
.table__dropdown__content button:hover {
    background-color: #F2F2F2;
}

.submenu__dropdown__btn {
    width: 100%;
    padding: 0 16px;
    text-align: left;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.submenu__dropdown__floated--active {
    background-color: #F2F2F2;
}

.submenu__dropdown__floated {
    width: 100%;
    padding: 4px 18px;
    text-align: left;
}

.submenu__dropdown__btn img {
    position: absolute;
    right: 10px;
    top: 10px;
}

@media screen and (max-width: 1455px) {
    .table__container {
        align-items: center;
    }
}

.show {
    display: block;
}

.submenu a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu a span {
    margin-right: 49px;
}

.submenu:hover .submenu__dropdown__content {
    display: block;
}

/*  */
.submenu__2 {
    position: relative;
}

.submenu {
    position: relative;
}

.submenu__dropdown__content {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
    background-color: #fff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border: 1px solid #d7d7d7;
}

.submenu__2 a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu__2 a span {
    margin-right: 75px;
}

.submenu__2:hover .submenu__dropdown__content__2 {
    display: block;
}

.submenu__dropdown__content:hover + button {
    background-color: #F2F2F2;
}

.submenu__dropdown__content__3 {
    display: none;
    position: absolute;
    left: 161px;
    top: 8px;
    background-color: #fff;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border: 1px solid #d7d7d7 !important;
}

.submenu__3 a {
    display: flex;
    align-items: center;
    height: 28px;
    width: 100%;
}

.submenu__3 a span {
    margin-right: 52px;
}

.submenu__3:hover .submenu__dropdown__content__3 {
    display: block;
}

.table__row__header {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
}

.table__row__header th {
    padding: 12px 13px;
    height: 40px;
}

.table__row__tr {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
}

.table__row__tr td {
    padding: 12px 25px;
}

.table__row__tr__td {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
}

.table__row__tr__td td {
    padding: 0 13px;
}

.submenu__dropdown__content__3:hover + button,
.submenu__dropdown__content__2:hover + button,
.submenu__dropdown__content:hover + button {
    background: #f2f2f2;
}

.TableNote {
    display: flex;
    flex-direction: column;
    padding-right: 5px;
}

.TableNote div {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.TableNote p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #313131;
    text-align: right;
}
.input__wrapper {
  position: relative;
}

.staff-search {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 17px;
  font-size: 14px;
  line-height: 16px;
  color: #535353;
  padding-left: 42px;
  width: 256px;
  height: 36px;
  transition: 0.2s ease;
}
.staff-search:hover {
  border-color: #a7a7a7
}

.staff-search:focus {
  border: 3px solid #56ccf2;
}

.staff-search-icon {
  position: absolute;
  left: 12px;
  top: 16px;
  cursor: pointer;
}

.staff-close-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: none;
}

.staff-close-btn:hover {
  background-color: #ececec;
}
.staff-close-btn{
  display: none;
}
.staff_show{
  display: block;
}
.staff_show:hover{
  cursor: pointer;
}

.staff-close-btn:hover .staff-close-icon {
  -webkit-filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
          filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
}

.kadri__modals {
  display: flex;
  align-items: center;
}
.kadri__modals__active {
  display: block;
}
.bg__layer {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 104%;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
table{
  border-spacing: 0.5;
}
.table__item__line:hover {
  background-color: #f2f2f2;
  border: 2px solid #59e4b1;
  cursor: pointer;
}
.table__item__td {
  padding: 0 8px;
  border-bottom: 1px #f2f2f2 solid;
  color: #535353;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
}
.table__item__td:first-child {
  padding: 0 8px 0 16px;
}
.table__item__td:last-child {
  padding-left: 16px;
  padding-right: 8px;
}
.selected {
  background: #DFFAF0;
}
.selected:hover {
  background: #DFFAF0;
}
.table__item__td {
  border: 2px solid transparent;
  border-bottom: 1px solid #f2f2f2;
}
.selected .table__item__td,
.selected:hover .table__item__td {
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
}
.selected:hover .table__item__td:first-child {
  cursor: pointer;
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
  border-left: 2px solid #59e4b1;
}
.selected:hover .table__item__td:last-child {
  cursor: pointer;
  border-top: 2px solid #59e4b1;
  border-bottom: 2px solid #59e4b1;
  border-right: 2px solid #59e4b1;
}
.table__item__line:hover .table__item__td__svg svg{
  margin-right: -2px;
}



.table__item__line__grey:hover {
  background-color: #dffaf0;
  border-bottom: 1px #f2f2f2 solid;
}
.table__item__line__grey:hover .table__item__td {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;

}
.table__item__line__grey:hover .table__item__td:first-child {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  border-left: 2px solid #f2f2f2;
  padding: 0 23px;
}
.table__item__line__grey:hover .table__item__td:last-child {
  cursor: pointer;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  border-right: 2px solid #f2f2f2;
  padding: 0 23px;
}
.table__item__line__grey:hover .table__item__td__svg svg{
  margin-right: -2px;
}

.DropdownButton {
    width: 100%;
    height: 28px;
    display: flex;
    padding: 6px 20px;
    align-items: center;
    justify-content: left;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.DropdownButton:hover {
    background: #F2F2F2;
}

.DropdownHint {
    position: absolute;
    bottom: -1px;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    background: #fff;
    width: 100%;
    z-index: 2;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    list-style: none;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}
.DropdownHint[data-active="true"]{
    border: none;
}

.DropdownHintRow {
    width: 100%;
    height: 36px;
    padding: 0 16px;
    text-align: left;
}
.DropdownHintRow:hover{
    background: #e3e3e3;
}

/* Стили контейнера */

.kadri__item__row {
    display: flex;
    align-items: baseline;
    margin-bottom: 26px;
}

.kadri__item__row:last-child {
    margin-bottom: 0;
}

#create .__react_component_tooltip {
    max-width: 270px;
    padding: 5px;
    text-align: center;
}

.back__btn {
    display: flex;
    align-items: center;
}

.back__btn img {
    margin-right: 20px;
}

.table__row__del .TableHeader span {
    font-weight: 500;
    margin-left: 5px;
}

.table__row__del .TableHeader {
    font-size: 12px;
    font-weight: 300;
    background: #fbfbfb;
    cursor: inherit !important;
}
.dropdown__works{
        margin-left: 8px;
}
.doc__add__wrapper{
    position: relative;
}
.doc__add__wrapper .error{
    position: absolute;
    bottom: 0;
    color: red;
    font-size: 10px;
    -webkit-transform: translate(0,100%);
            transform: translate(0,100%);
}
.table__container__scroll .error{
    color: red;
    font-size: 10px;
    margin-left: 8px;
}
.dropdown__works .table__dropdown__content{
    padding: 0;
}
.dropdown__works>button{
    font-size: 12px;
    color: black;
}
.add__modal_container  .table__value__item span{
     display: flex;
    align-items: center;
}
.dropdown__works .submenu__dropdown__btn{
    font-size: 12px;
}
.dropdown__works .submenu__dropdown__btn span{
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: black;
}
.label {
    position: relative;
}

.label:before {
    content: "";
    position: absolute;
    left: -8px;
    width: 10px;
    border-radius: 100%;
    height: 10px;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
}

.label.label--green:before {
    background: green;
}

.label.label--red:before {
    background: #d92727;
}

.label.label--orange:before {
    background: orange;
}

.label.label--gray:before {
    background: gray;
}


[data-active="active"] {
    -webkit-animation: shake-horizontal 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}

@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}


.submenu_back {
    border-top: 1px solid #c9c9c9;
    padding-top: 6px;
}

.submenu_back > .submenu__dropdown__btn:hover {
    background: #fee5e5;
}

.submenu_back > .submenu__dropdown__btn {
    color: #bc2c2c;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 4px 16px;
}

.back__btn span {
    color: #009640;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
}

.back__btn:hover {
    background-color: #f8f8f8;
}

.checkbox__deletedList *,
.checkbox__deletedList {
    cursor: pointer;
}

.checkbox__deletedList {
    display: flex;
    align-items: center;
}

/* DropDown стили  */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    right: 38px;
    background-color: #fff;
    width: 160px;
    height: 103px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content button {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 22px;
    color: black;
    height: 26px;
    font-size: 13px;
    font-family: Roboto;
    text-decoration: none;
    display: block;
    margin-bottom: 3px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.dropdown-content button:first-child {
    margin-top: 8px;
}

.dropdown-content button:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

/* Затемнение фона */

.bg__layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 134%;
}

.right-0 {
    right: 0 !important;
}

/*Стили карточки информации*/

.kadri__item__content {
  margin-right: 26px;
}

.kadri__item__info {
  width: 434px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: #313131;
  font-size: 13px;
  font-weight: 400;
  background-color: #fff;
  margin-left: 8px;
}

.table__dropdown__column ul > li > span {
  width: 100%;
  display: flex;
  padding: 4px 9px;
}

.table__dropdown__hover > li > span {
  width: 100%;
  display: flex;
  padding: 4px 9px;
}

.table__dropdown__column ul ul > li:before {
  content: "-";
  position: absolute;
  font-size: 20px;
  left: 9px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.table__dropdown__hover ul > li::before {
  content: "-";
  position: absolute;
  font-size: 20px;
  left: 9px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.edit_rent-document__wrapper {
  width: 100%;
  position: relative;
}

.document_button_wrapper {
  position: absolute;
  left: 50px;
}

.edit_rent-document__wrapper .submenu__dropdown__btn {
  white-space: normal;
}

.table__dropdown__column ul ul > li {
  padding: 4px 18px;
  position: relative;
}

.table__dropdown__hover ul > li {
  padding: 4px 18px;
  position: relative;
}

.table__dropdown__column ul ul > li {
  cursor: pointer;
}

.table__dropdown__hover ul > li {
  cursor: pointer;
}

.table__dropdown__column ul ul {
  list-style: none;
}

.table__dropdown__hover ul {
  list-style: none;
}

.table__dropdown__column .hover:hover {
  background-color: #f2f2f2;
}
.table__dropdown__hover .hover:hover {
  background-color: #f2f2f2;
}

.table__dropdown__hover > li:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.table__dropdown__column ul {
  list-style: none;
}
.table__dropdown__hover {
  list-style: none;
}

.table__dropdown__column {
  position: relative;
}

/* .submenu__dropdown__btn__hover:hover + .table__dropdown__hover {
  opacity: 1;
  pointer-events: all;
} */

.table__dropdown__hover {
  position: absolute;
  left: 10px;
  top: 38px;
  opacity: 1;
  pointer-events: none;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
  font-size: 13px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  width: -webkit-max-content;
  width: max-content;
  white-space: nowrap;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 9px 0;
}

.conditions__transfer input::-webkit-input-placeholder {
  color: hsl(0, 0%, 50%);
}

.conditions__transfer input::placeholder {
  color: hsl(0, 0%, 50%);
}

.conditions__transfer input {
  width: 100%;
  border: 1px solid rgba(193, 193, 193, 0.8);
}

.conditions__transfer .conditions__row .error {
  bottom: 1px;
  font-size: 10px;
}

.conditions__transfer .conditions__content-left p {
  margin: 0 0 10px 0;
}

.conditions__transfer .conditions__row {
  margin-bottom: 10px;
}

.conditions__transfer label {
  width: 100%;
}

@media screen and (max-width: 1155px) {
  .kadri__item__info {
    width: 390px;
  }
}

.kadri__item__info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 26px 8px;
}

.kadri__item__info__header-text {
  color: #313131;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.kadri__item__info__close-btn {
  border: none;
  position: relative;
  left: 2px;
  width: 28px;
  height: 28px;
  background-color: transparent;
  cursor: pointer;
}

.kadri__item__info__close-btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.kadri__item__info__close-btn:hover {
  background-color: #ececec;
}

.kadri__item__info__close-btn:hover .info__close-icon {
  -webkit-filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
          filter: invert(57%) sepia(4%) saturate(23%) hue-rotate(314deg) brightness(88%)
    contrast(97%);
}

.kadri__item__info__hr {
  width: 392px;
  margin: 0 auto;
  height: 1px;
  background: #d7d7d7;
}

.kadri__item__info__content {
  margin-top: 22px;
  padding-bottom: 19px;
}

.kadri__item__info__content__2 {
  padding: 15px 25px 15px;
  display: flex;
  flex-direction: column;
}

.kadri__item__info__row {
  display: flex;
  align-items: center;
  margin: 0 0 2px 0;
  /* padding: 5px; */
  border-radius: 3px;
}

.kadri__item__info__row:hover p {
  background: #f2f2f2;
}

.kadri__item__info__row:hover .kadri__item__info__box-left,
.kadri__item__info__row:hover .kadri__item__info__box-right {
  background: transparent;
}

.kadri__item__info__row p {
  align-items: center;
  /* margin: 0 0 7px 0; */
  padding: 6px;
  border-radius: 3px;
}

.kadri__item__info__row img {
  width: 26px;
  margin: 0 24px 0 0;
}

/* .kadri__item__info__row:first-child {
  margin-top: 25px;
}
.kadri__item__info__row:last-child {
  margin-bottom: 15px;
}
.kadri__item__info__row:hover {
  cursor: pointer;
} */

.kadri__item__info__box-left {
  width: 140px;
}

.kadri__item__info__wrapper {
  width: 140px;
  display: flex;
  align-items: center;
}

.kadri__item__info__icon {
  width: 14px !important;
  height: 14px !important;
  margin: 0 0 0 7px !important;
  cursor: pointer;
}

.kadri__item__info__box-right {
  width: 280px;
}

.kadri__item__info__box-left p {
  margin-bottom: 15px;
}

.kadri__item__info__box_tab {
  padding-left: 20px;
}

.kadri__item__info__row {
  display: flex;
  align-items: flex-start;
}

.kadri__item__info__box_token-tab {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
}

/*.kadri__item__info__box-left p:nth-child(2) {*/
/*  margin-bottom: 20px;*/
/*}*/

/*.kadri__item__info__box-left p:nth-child(4) {*/
/*  margin-bottom: 18px;*/
/*}*/

/* .kadri__item__info__box-left p span:nth-child(5) {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.kadri__item__info__box-left p:nth-child(5) {
  margin-bottom: 31px;
  display: flex;
  align-items: center;
} */
/* .kadri__item__info__box-left p span:nth-child(6) {
  display: flex;
  align-items: center;
} */

/* .kadri__item__info__box-left p:nth-child(6) {
  display: flex;
  align-items: center;
} */

.kadri__item__info__box-right p {
  margin-bottom: 15px;
}

.kadri__item__info__span {
  background: #ffdc64;
}

.kadri__item__info__box-right-section {
  display: flex;
  align-items: center;
}

.kadri__item__info__box-right-button {
  border: none;
  position: relative;
  bottom: 7px;
  left: 15px;
  width: 116px;
  height: 36px;
  background: #0dadd9;
  border-radius: 3px;
  color: #fff;
  padding-left: 20px;
  transition: all 0.2s ease-in-out;
}

.kadri__item__info__box-right-button:hover {
  background: #3cb5d6;
}

.kadri__item__info__box-right-icon {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

/* Стили добавления токена */

.add__token {
  width: 352px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

@media screen and (max-width: 1455px) {
  .add__token {
    margin-bottom: 27px;
  }
}

.add__token__left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.add__token__left img {
  padding: 11px 18px;
  background-color: #ffdf71;
  margin-left: 3px;
  border-radius: 0px 4px 4px 0px;
}

.add__token__left__active img {
  background-color: #ffc709;
  cursor: pointer;
}

.add__token__close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.add__token__close img {
  padding: 7px;
  border-radius: 3px;
}

.add__token__close img:hover {
  background-color: #ececec;
  cursor: pointer;
}

/* Стили карточки поиска */
.add__token__search {
  width: 50px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #ffdc64;
  transition: 0.2s ease;
  margin-left: 1px;
}

.add__token__search:hover {
  background: #f2c94c;
}

.add__token__search__disabled {
  background: #fafafa;
  border: 2px solid #efefef;
}

.add__token__search__disabled svg {
  opacity: 0.6;
}

.add__token__search__disabled:hover {
  background: #fafafa;
}

.add__token__search__2 {
  position: absolute;
  right: 0;
  /*z-index: 9999;*/
  /*top: 193px;*/
  /*right: 186px;*/
  width: 399px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.add__token__search__2 input:focus {
  border: 3px solid #56ccf2;
}

.add__token__search__2 input {
  font-size: 13px;
}

/* Стили карточки Кадровых документов */
.kadri__item__info__box__doc-left {
  margin-top: 22px;
  margin-left: 25px;
  width: 140px;
}

.kadri__item__info__box__doc-left p:hover {
  cursor: pointer;
}

.kadri__item__info__box__doc-right p:hover {
  cursor: pointer;
}

.kadri__item__info__box__doc-right {
  width: 320px;
  margin-right: 244px;
  margin-top: 22px;
}

.kadri__item__info__box__doc-left p {
  margin-bottom: 10px;
  text-align: center;
  margin-left: -15px;
}

.kadri__item__info__box__doc-right p {
  margin-bottom: 20px;
}

/* Стили карточки отпусков */

.conditions {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 280px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .conditions__row img {
  position: absolute;
} */

/* .conditions__header {
  padding: 15px 25px 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: black;
  font-weight: 500;
} */

.conditions__close-icon {
  cursor: pointer;
}

.conditions__calendar-icon {
  position: absolute;
  right: 0;
}

/* .select {
  font-size: 13px;
  line-height: 15px;
  width: 100%;
  height: 34px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  color: #bfb5bd;
  padding-left: 11px;
} */

.conditions__clock-icon--1 {
  left: 115px;
}

.conditions__clock-icon--2 {
  right: 0px;
}

.conditions__reward {
  right: 0px;
}

/* Стили карточки непрацездатності */

.conditions__nep {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__nep {
  margin-top: -25px;
  margin-left: 222px;
}

/* Стили карточки прогулов_1 */

.conditions__truancy {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 260px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__truancy {
  margin-top: -10px;
  margin-left: 222px;
}

/* Стили карточки прогулов_2 */

.conditions__truancy__2 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 210px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__truancy__2 {
  margin-top: -10px;
  margin-left: 222px;
}

/* Стили карточки поиска */

.search__container {
  background-color: #fff;
  width: 500px;
  height: 167px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 990;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search__list li {
  font-size: 13px;
  cursor: pointer;
  padding: 8px;
}

.search__list li:hover {
  background: #e3e3e3;
}

.search__list {
  position: absolute;
  bottom: -1px;
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%);
  background: white;
  width: 100%;
  z-index: 2;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  list-style: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.search__list.active {
  border: none;
}

.search__header {
  width: 100%;
  background-color: #219653;
  padding: 10px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search__header span {
  color: #fff;
  font-size: 16px;
}

.search__header img {
  padding: 7px;
  border-radius: 3px;
}

.search__header img:hover {
  background-color: #4daf77;
  cursor: pointer;
}

.search__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__content p {
  margin: 0 10px;
}

.input__row:not(:last-child) {
  margin-right: 12px;
}

.input__row {
  display: flex;
  align-items: center;
}

.input__row input {
  width: 60px;
  text-align: center;
  padding: 5px 10px;
}

.inputRight {
  background-color: #f8f8f8;
  height: 32px;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
  transition: 0.2s ease;
  cursor: pointer;
}

/*  */

.conditions__workdoc__4 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 320px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__header img {
  padding: 2px;

  border-radius: 3px;
}

.conditions__header img:hover {
  background-color: #f8f8f8;
}

/*  */
.conditions__workdoc__5 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 410px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__workdoc__6 {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 600px;
  height: 460px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.conditions__buttons__workdoc__6 {
  margin-top: -18px;
  margin-left: 222px;
}

/* Стили для KadriCardsPersonEdit */
.tabcontent {
  overflow-y: scroll;
  height: 85%;
}

.tab__modal {
  position: fixed;
  width: 1020px;
  height: 590px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #fff;
}

.tab {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #009640;
}

.tab__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 1px);
  z-index: -1;
  background-color: #009640;
}

.tab__btn {
  padding-top: 20px;
  padding-left: 21px;
  height: 60px;
  overflow: hidden;
  /* border: 1px solid #ccc; */
  /* background-color: #009640; */
}

.tab__close {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.tab__close:hover {
  background-color: #4daf77;
  cursor: pointer;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  width: 168px;
  height: 40px;
  transition: 0.3s;
  font-size: 17px;
  color: #fff;
}

.tab button:hover {
  background-color: #4daf77;
}

.tab button.active {
  background-color: #fff;
  color: #008137;
}

/* .tabcontent {
  padding: 65px 40px 40px 40px;
} */

/*  */

.table__container {
  /*//padding: 64px 40px 40px 40px;*/
}

.table__container table {
  width: 105.5%;
}

@media screen and (max-width: 1546px) {
  .table__container table {
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  /*.add__token__search__2{*/
  /*  top: 193px;*/
  /*  right: 1px;*/
  /*}*/
}

.table__place span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #535353;
}

.table__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 39px;
}

.table__value {
  display: flex;
  flex-direction: column;
}

.table__value__row {
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;
  align-items: flex-end;
}

.table__value__item__bottom {
  margin-top: auto;
}

.table__value__item {
  display: flex;
  flex-direction: column;
  color: #535353;
  margin-right: 16px;
}

.table__value__item span,
.table__value__item__input span {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #535353;
  margin-bottom: 2px;
}

.null {
  margin-right: 0;
}

.table__value__item__row {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.table__value__item__input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
}

.item__input__check {
  background-color: #f8f8f8;
  height: 32px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 13px;
  padding-right: 13px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px;
  transition: 0.2s ease;
  cursor: pointer;
}

.item__input__check:focus + .table__dropdown__content,
.table__dropdown__content:hover {
  display: block;
  right: 0;
}

.left-0 {
  left: 0 !important;
}

.item__input__check span input {
  zoom: 1.5;
}

/*  */

.table__container__full {
  padding: 26px 40px 40px 40px;
}

/* Сетка размеров для таблицы */

.table-lg {
  width: 275px;
}

.table-md {
  width: 132px;
}

.tr-sm td {
  padding: 0;
  margin: 0;
}

.tr-sm td:input {
  border: none;
}

/*  */

.quota__container {
  padding: 21px 35px;
}

.quota__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 11px;
}

.quota__row__footer {
  display: flex;
  margin-top: 4px;
}

.quota__row__header {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.quota__row__header span {
  margin-right: 25px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.quota__row__table table {
  border-collapse: collapse;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.quota__row__table tr {
  display: flex;
  align-items: center;
  border: 0.5px solid #fff;
}

.quota__row__table td {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  height: 45px;
  justify-content: center;
}

.quota__row__table td:first-child {
  padding: 5px 7px;
}

.quota__row__table td:nth-child(2) {
  border-left: 2px solid #fff;
}

.quota__row__table td:last-child {
  border-left: 2px solid #fff;
}

.quota__row__table td span {
  border: 1px solid #bdbdbd;
  width: 177px;
  height: 26px;
  display: flex;

  align-items: center;
}

.quota__row__table td input,
.quota__row__table td select {
  width: 100%;
  height: 26px;
  border: 1px solid #bdbdbd;
  padding-left: 8px;
}

.quota__row__table td span:nth-child(2) {
  padding-left: 8px;
}

.quota__row__table__trash {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid #fff;
}

.quota__row__table__trash:hover {
  cursor: pointer;
  background-color: #e0e0e0;
  transition: all 0.2s ease-in-out;
}

.quota__row__select {
  margin-right: 12px;
}

/*  */

.history__container {
  overflow-y: auto;
}

.history__container__settings {
  padding: 0;
}

.history__table {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
}

.history__table tr td {
  padding: 2px;
  padding-left: 8px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.history__table tr td:first-child {
  padding-left: 8px;
  border: 1px solid #f2f2f2;
  width: 120px;
}

.history__table tr td:nth-child(3) {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #f2f2f2;
}

.history__table tr td:last-child {
  border: 1px solid #f2f2f2;
  border-left: none;
  padding-left: 8px;
}

/*  */

/*  */

.document__container {
  padding: 36px 10px 5px 63px;
  display: flex;
  overflow: scroll;
}

.document__row {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.document__row__header {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
}

.document__row__icon:disabled {
  pointer-events: none;
}

.document__row__icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  border-radius: 4px;
}

.document__row__icon:hover {
  background: #f2f2f2;
}

.document__row__btn {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document__row__header:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.document__row__header img:hover {
  cursor: pointer;
}

.document__row__header img:last-child:hover {
  background-color: #ececec;
}

.document__row__header span {
  font-size: 14px;
  margin-left: 12px;
}

.document__row__container:last-child {
  margin-bottom: 10px;
}

.document__img {
  width: 300px;
  max-height: 400px;
  object-fit: contain;
}

.row__vertical__line {
  height: 400px;
  position: absolute;
  margin-left: 59px;
  margin-right: 59px;
  left: 330px;
  width: 1px;
  background-color: #c1c1c1;
}

.document__row__content {
  display: none;
  flex-direction: column;
}

.document__row__content__item {
  display: flex;
  align-items: center;
  margin-left: 38px;
  margin-top: 0px;
}

.document__row__content__item span {
  font-size: 14px;
  margin-right: 12px;
  color: #2f80ed;
  cursor: pointer;
}

.document__active {
  display: flex;
}

.span__active {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

/*  */

.tab__modal__settings {
  position: fixed;
  width: 590px;
  height: 595px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
}

.tab__settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.tab__btn__settings {
  padding-top: 21px;
  padding-left: 20px;
  height: 60px;
  overflow: hidden;
}

.tab__close__settings {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.tab__close__settings:hover {
  background-color: #ececec;
  cursor: pointer;
}

.tab__settings button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  width: 140px;
  height: 40px;
  transition: 0.3s;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #4f4f4f;
  border: 1px solid #efefef;
  border-radius: 7px 7px 0px 0px;
}

.tab__settings button:hover {
  background-color: #efefef;
}

.tab__settings button.active {
  border-bottom: 5px solid #828282;
}

/*  */

.checkbox-google {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  -webkit-user-select: none;
          user-select: none;
}

.checkbox-google .checkbox-google-switch {
  display: inline-block;
  width: 46px;
  height: 16px;
  border-radius: 20px;
  position: relative;
  top: 6px;
  vertical-align: top;
  background: #dcdcdc;
  transition: 0.2s;
}

.checkbox-google .checkbox-google-switch:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -5px;
  left: -1px;
  background: #c4c4c4;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: 0.15s;
}

.checkbox-google input[type="checkbox"] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-google input[type="checkbox"]:checked + .checkbox-google-switch {
  background: #aedec2;
}

.checkbox-google
  input[type="checkbox"]:checked
  + .checkbox-google-switch:before {
  background: #27ae60;
  -webkit-transform: translateX(22px);
          transform: translateX(22px);
}

/* Hover */
.checkbox-google
  input[type="checkbox"]:not(:disabled)
  + .checkbox-google-switch {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.3);
}

/* Active/Focus */
.checkbox-google
  input[type="checkbox"]:not(:disabled):active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):focus
  + .checkbox-google-switch:before {
  -webkit-animation: checkbox-active-on 0.5s forwards linear;
          animation: checkbox-active-on 0.5s forwards linear;
}

@-webkit-keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

@keyframes checkbox-active-on {
  0% {
    box-shadow: 0 0 0 0 rgba(212, 212, 212, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(212, 212, 212, 0.5);
  }
}

.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:active
  + .checkbox-google-switch:before,
.checkbox-google
  input[type="checkbox"]:not(:disabled):checked:focus
  + .checkbox-google-switch:before {
  -webkit-animation: checkbox-active-off 0.5s forwards linear;
          animation: checkbox-active-off 0.5s forwards linear;
}

@-webkit-keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

@keyframes checkbox-active-off {
  0% {
    box-shadow: 0 0 0 0 rgba(154, 190, 247, 0);
  }
  99% {
    box-shadow: 0 0 0 10px rgba(154, 190, 247, 0.5);
  }
}

/* Disabled */
.checkbox-google input[type="checkbox"]:disabled + .checkbox-google-switch {
  -webkit-filter: grayscale(60%);
          filter: grayscale(60%);
  border-color: rgba(0, 0, 0, 0.1);
}

.checkbox-google
  input[type="checkbox"]:disabled
  + .checkbox-google-switch:before {
  background: #eee;
}

/*  */

.settings__container {
  padding: 0 20px;
}

.settings__row {
  margin-top: 15px;
}

.settings__row__header {
  display: flex;
  justify-content: space-between;
}

.settings__row__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
}

.settings__row__content span {
  font-size: 12px;
}

/*  */

.settings__container__2 {
  padding: 26px 20px;
}

.settings__row__header__2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.settings__row__2 {
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
}

.settings__row__2__content {
  font-size: 13px;
  display: flex;
  /* justify-content: space-between; */
}

.content__left {
  margin-right: 2px;
}

.content__vertical {
  width: 1px;
  height: 333px;
  background-color: #e0e0e0;
}

.content__left__header {
  display: flex;
  flex-direction: column;
  width: 271px;
}

.header__row {
  height: 19px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  width: 235px;
}

.content__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  height: 18px;
}

.content__row__grey {
  background-color: #f2f2f2;
  width: 272px;
  height: 30px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content__row__grey span {
  font-size: 12px;
}

.content__row__grey span img {
  margin-left: 4px;
}

.content__row__grey span img:hover {
  cursor: pointer;
}

.content__right {
  display: flex;
  justify-content: center;
  padding-top: 11px;
  padding-left: 30px;
}

.content__right__name {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  height: 305px;
  justify-content: space-between;
}

.content__right__value {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 305px;
  justify-content: space-between;
}

/*  */

.doc__modal__container {
  position: fixed;
  z-index: 990;
  top: 2.5%;
  left: 2.5%;
  background-color: #fff;
  width: 95%;
  height: 95%;
  overflow-y: hidden;
  padding: 20px 18px;
  border: solid 1px #8c8c8c;
}

.modal__header__title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__header__title img {
  padding: 7px;
}

.modal__header__title img:hover {
  cursor: pointer;
  background-color: #ececec;
}

.modal__header__select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.modal__header__select__journal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.modal__header__button__journal {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.doc__modal__footer__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.doc__modal__footer__btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid #efefef;
  background-color: #fafafa;
  border-radius: 3px;
  width: 162px;
  height: 54px;
}

.doc__modal__table {
  padding: 2px;
  max-height: calc(100vh - 245px);
  min-height: 350px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.doc__modal__table table {
  margin-bottom: 0;
}

.buttonVacation {
  background: green;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: 400;
  font-size: 28px;
  border-radius: 100%;
  margin: 0 auto;
}

.doc__modal__table .react-datepicker__input-container input {
  padding: 0 8px;
  height: 24px;
}

.doc__modal__table thead {
  position: -webkit-sticky;
  position: sticky;
  top: -3px;
  background: white;
  z-index: 1;
}

.doc__modal__table__wage {
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  height: 100%;
  padding: 2px 2px 0;
  margin: 0;
}

.doc__modal__table__wage table {
  height: -webkit-max-content;
  height: max-content;
}

.doc__modal__table__wage thead {
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: -3px;
  z-index: 1;
}

.journal__modal__table table {
  margin-bottom: 0;
}

.journal__modal__table.journal__modal__table--scroll {
  max-height: calc(100vh - 180px);
}

.journal__modal__table {
  max-height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 2px;
  margin: 0 0 10px;
}

.journal__modal__table .hover-underline:hover {
  font-weight: bold;
  transition: 0.5s ease;
}

.journal__modal__table thead {
  position: -webkit-sticky;
  position: sticky;
  top: -3px;
  background: white;
}

.doc__modal__footer__btn button:hover {
  background-color: #efefef;
  cursor: pointer;
}

.doc__modal__footer__btn button p {
  margin-left: 16px;
  font-size: 13px;
  font-weight: bold;
}

.doc__modal__zoom {
  position: fixed;
  z-index: 9999;
  left: 22%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 300px;
  height: 400px;
}

.doc__modal__zoom img {
  width: 120px;
  height: 120px;
}

.doc__modal__zoom img:hover {
  cursor: pointer;
}

/*  */
.doc__modal__table__wage .__react_component_tooltip {
  max-width: 340px;
}

.wage__table__pencil img:hover {
  background-color: #ececec;
  border-radius: 3px;
  cursor: pointer;
}

.doc__modal__footer__btn__wage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 40px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.doc__modal__footer__btn__wage button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 2px solid #efefef;
  background-color: #fafafa;
  border-radius: 3px;
  width: 162px;
  height: 54px;
}

.doc__modal__footer__btn__wage button:hover {
  background-color: #efefef;
  cursor: pointer;
}

.doc__modal__footer__btn__wage button p {
  margin-left: 16px;
  font-size: 13px;
  font-weight: bold;
}

.doc__modal__footer__btn__wage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.doc__modal__table__wage__total {
  padding: 0 40px;
  display: flex;
}

.doc__modal__table__wage__item {
  background-color: #fff;
  width: 73px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  position: absolute;
  bottom: -81px;

  right: 0;
}

.modal__footer__pay {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 92px;
  padding: 8px 9px;
  border: 2px solid #efefef;
  margin-left: 50px;
  margin-top: 35px;
}

.modal__footer__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal__footer__header span {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.modal__footer__header button {
  width: 82px;
  height: 30px;
  background-color: #74b027;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.modal__footer__header button:hover {
  background-color: #8bc73d;
}

.modal__footer__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: inset 0px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  height: 30px;
}

.modal__footer__content span {
  font-size: 12px;
  line-height: 14px;
}

/*  */

.workTime__modal__table {
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
  padding: 2px;
  margin: 0;
}

.workTime__modal__table table > tr:nth-child(1) {
  position: -webkit-sticky;
  position: sticky;
  top: -2px;
  background: white;
}

.workTime__modal__table table {
  border-collapse: collapse;
}

.workTime__modal__table table,
.workTime__modal__table table th,
.workTime__modal__table table td {
  border: 0.5px solid #d7d7d7;
}

.workTime__modal__table table tr td {
}

.table__lg {
  width: 236px;
  height: 40px;
  padding-left: 6px;
  font-size: 13px;
  line-height: 15px;
}

.table__sm {
  width: 35px;
  height: 40px;
  font-size: 13px;
  font-weight: 400;
}

.table__sm {
  text-align: center;
}

.table__sm:hover > .workTime__select {
  display: block;
}

.table__sm:hover > .table__workDay {
  display: none !important;
}

.table__day {
  color: #4f4f4f;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.workTime__select {
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 2px 5px 3px;
  width: 100%;
  height: 100%;
  color: #000;
  border: 3px solid #2f80ed;
  border-radius: 4px;
  font-size: 11px;
  display: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAGCAYAAAAPDoR2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgBXYzRDYAwCEQB/fHPEXQTVnID2MAR3EBH6CiO0AlawdCkegkcl0cORWQBAJ+/7tE3EZ1mc09KKeuQUsrMPCEiN1BrVWu8KMJulltdZHihfTnY/LYGifyVqh59fgARQCFYHNoFhQAAAABJRU5ErkJggg==) no-repeat bottom center
    #fff;
}

.addXmlFileButton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  border-radius: 8px;
  background: #e8f8ff;
}

.addXmlFileButton:hover {
  opacity: 0.8;
}

.jksTableArrowDown {
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 2px 5px 3px;
  width: 100%;
  height: 100%;
  color: #000;
  border: 3px solid #2f80ed;
  border-radius: 4px;
  font-size: 11px;
  display: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAGCAYAAAAPDoR2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABiSURBVHgBXYzRDYAwCEQB/fHPEXQTVnID2MAR3EBH6CiO0AlawdCkegkcl0cORWQBAJ+/7tE3EZ1mc09KKeuQUsrMPCEiN1BrVWu8KMJulltdZHihfTnY/LYGifyVqh59fgARQCFYHNoFhQAAAABJRU5ErkJggg==) no-repeat bottom center
    #1c1b1f;
}
.modal__header__jksStatistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;
}
.modal__header__jksStatistics .jksStatisticsDocuments {
  display: flex;
  flex-direction: column;
}
.jksDocumentCounter {
  font-size: 13px;
  margin-top: 5px;
}
.jksState {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.jksState div:nth-child(2n) {
  margin-left: 10px;
}
.jksDatePicker {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 200px;
}
.jksDatePicker .jks_modal__header__select:nth-child(2n) {
  margin-left: 15px;
}
.jksTbody {
  position: relative;
}
.signReportSection {
  margin: 15px 15px;
  display: flex;
  justify-content: space-between;
}
.signReportButton {
  margin-top: 15px;
  border-radius: 8px;
  background: linear-gradient(90deg, #b000b4 0%, #00b4b4 98.23%);
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  color: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.signReportDelete {
  position: absolute;
  right: 16px;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.06);
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
  color: #e40303;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.signReportButton:disabled {
  opacity: 0.3;
}

.pagination_item:nth-child(2n) {
  margin: 13px 14px;
}
.pagination_item {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-shrink: 0;
}

/*  */

.add__modal_container {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  width: 80%;
  height: 90%;
  padding: 23px 35px;
  border: solid 1px #8c8c8c;
}

.add__modal_container input,
.add__modal_container .text__table__content,
.add__modal_container textarea {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #313131;
}

.add__modal_container textarea {
  padding: 8px;
  height: 34px;
  min-height: 34px;
}

.add__modal_container .table__container__scroll {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.add__modal_container textarea {
  padding: 8px;
  height: 34px;
  min-height: 34px;
}

.add__modal_container textarea#description {
  min-height: 94px;
}

.add__modal_container input::-webkit-input-placeholder {
  color: #c9c9c9;
  font-size: 13px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

.add__modal_container input::placeholder {
  color: #c9c9c9;
  font-size: 13px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal__header img {
  padding: 7px;
  border-radius: 3px;
}

.modal__header img:hover {
  background-color: #ececec;
  cursor: pointer;
}

.modal__header__left {
  margin-top: 20px;
  display: flex;
}

.modal__header__content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.header__content__item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.header__content__text {
  font-size: 13px;
  font-weight: normal;
  border: 1px solid #e3e3e3;
  padding: 2px 0px 20px 6px;
  border-radius: 4px;
}

.text__table__left {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  font-size: 13px;
  line-height: 15px;
  border: 1px solid #e3e3e3;
}

.header__content__text__table {
  display: flex;
}

.text__table__lg {
  height: 89px;
}

.text__table__sm {
  height: 34px;
}

.text__table__content {
  display: flex;
  padding-left: 3px;
  align-items: center;
  width: 863px;
  border: 1px solid #e3e3e3;
  font-size: 13px;
  resize: vertical;
  outline: none;
  min-height: 35px;
  font-family: Roboto;
  font-weight: 400;
  color: #313131;
  height: auto;
}

.text__table__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  border-left: none;
}

.text__table__right:hover {
  background-color: #e3e3e3;
  cursor: pointer;
}

.modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.modal__footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  background-color: #f2f2f2;
  border: none;
}

.modal__footer button:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}

.modal__footer button img {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.modal__footer__green {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.search-input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  transition: 0.2s ease;
  position: relative;
  height: 23px;
  width: 42px;
  padding: 2px 5px 0 2px;
  color: #000;
}

.search-input:focus {
  border: 3px solid #56ccf2;
}

.table__choose {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.table__chooseItem {
  height: 28px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 0 8px;
}

.table__chooseItem.selected {
  background: #009640;
  color: white;
}

.disabled__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #009640;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009640 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

.google-places {
    position: relative;
    width: 100%;
}

.google-places input {
    width: 100%;
}

.google-places input.error_border {
    border: 1px solid red;
}

.google-places__input {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.google-places__input:not(:first-child) {
    margin-left: 5px;
}

.street {
    margin-left: 5px;
}

.google-places .error {
    position: absolute;
    bottom: 0;
    color: red;
    font-size: 12px;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
}

.google-places__list > li:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.google-places__list > li {
    padding: 5px 20px;
    font-size: 13px;
    line-height: 16px;
}

.google-places__list {
    display: none;
}

.google-places__list.active {
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    width: 100%;
    background: white;
    list-style: none;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    max-height: 150px;
    overflow-y: auto;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0;
  border-radius: 0 !important;
  width: 30px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background: #4caf50;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4caf50;
}
.react-datepicker__input-container input:disabled {
  background-color: #f2f2f2 !important;
  color: black;
  pointer-events: none;
}
.react-datepicker__input-container input {
  width: 100%;
  min-width: 100px;
  height: 34px;
  background: #ffffff;
  border: 1px solid rgba(193, 193, 193, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 18px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  opacity: 0;
  width: 35%;
  outline: 0;
  position: absolute;
  left: 95px;
  top: 0;
  right: 0px;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker {
  border-radius: 0 !important;
  border-color: #4caf50;
}

.react-datepicker__triangle {
  display: none;
}

.passport {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background: white;
    z-index: 10;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}


.passport__wrapper {
    max-width: 555px;
    border: 1px solid #EBEBEB;
    border-radius: 12px;
    background: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

.passport__wrapper > *:not(:last-child) {
    margin-bottom: 24px;
}

.passport__box img {
    margin-right: 30px;
}

.passport__box p {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.passport__box {
    padding: 24px 16px;
    background: #FEFFCA;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.passport__btn-download {
    border: 1px solid #C9C9C9;
    border-radius: 8px;
    padding: 36px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #ADADAD;
    width: 100%;
}
.passport__file{
    position: relative;
    width: 100%;
}
.passport__file input[type="file"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.passport__btn-next {
    padding: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0361D0;
}

.table__conditions {
    max-width: 500px;
    margin: 0 auto;
}

.table__conditions_column {
    display: flex;
    align-items: center;
    width: 100%;
}

.table__conditions_column:not(:last-child) {
    margin-bottom: 8px;
}

.table__conditions-select {
    border: 1px solid rgba(193, 193, 193, 0.8);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 18px;
    height: 28px;
}

.table__conditions_field {
    position: relative;
}

.table__conditions_field .error {
    position: absolute;
    color: red;
    font-size: 9px;
    left: 0;
}

.table__conditions input:focus {
    border: 1px solid black;
}

.table__conditions input:hover {
    border: 1px solid #7c7c7c;
}

.select__conditions {
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 17px;
    font-size: 13px;
    width: 100%;
}

.table__conditions_group .small-input {
    max-width: -webkit-max-content;
    max-width: max-content;
    width: 60px;
    text-align: center;
    margin-left: 8px;
}

.table__conditions_group {
    display: flex;
}

.table__conditions input:disabled {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
}

.table__conditions input {
    background: #ffffff;
    width: 200px;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 28px;
    padding: 10px 17px;
    font-size: 13px;
    line-height: 16px;
    color: black;
    transition: 0.2s ease;
}

.table__conditions .react-datepicker__input-container input {
    height: 28px;
    width: 200px;
}

.table__conditions-date > label:not(:first-child) {
    margin-left: 8px;
}

.table__conditions-date .react-datepicker__input-container input {
    width: 80px;
    padding: 5px;
}

.table__conditions_label {
    width: 160px;
    min-width: 160px;
    font-size: 13px;
    margin-right: 20px;
}
.react-datepicker__month-wrapper{
    display: flex;
}

.button__loader {
    transition: padding-left .5s linear;
    padding-left: 0;
    transition-delay: .3s;
    position: relative;
}

.button__loader.active {
    padding-left: 40px;
    transition: padding-left .5s linear;
}

.button__loader.active .button__loader__sinner {
    opacity: 1;
    transition: opacity .5s linear;
    transition-delay: .3s;
}

.button__loader .button__loader__sinner {
    opacity: 0;
    transition: opacity .5s linear;
}

.button__loader__sinner {
    color: inherit;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 10px;
    text-indent: -9999em;
    -webkit-animation: mulShdSpin 1.3s infinite linear;
            animation: mulShdSpin 1.3s infinite linear;
}

@-webkit-keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
        2em -2em 0 0em, 3em 0 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
        3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em,
        2em -2em 0 0, 3em 0 0 0.2em,
        2em 2em 0 0, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
        -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
        -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
        -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em,
        2em -2em 0 0em, 3em 0 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
        3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em,
        2em -2em 0 0, 3em 0 0 0.2em,
        2em 2em 0 0, 0 3em 0 -1em,
        -2em 2em 0 -1em, -3em 0 0 -1em,
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
        -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
        -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
        -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
        -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
  
.conditions__workdoc {
    background-color: #fff;
    padding: 6px 28px 38px;
    position: fixed;
    z-index: 9999;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 600px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.conditions__workdoc .react-datepicker__input-container input{
    padding: 0 14px;
    max-width: 143px;
}
.conditions__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: black;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 7px;
}

.conditions__content {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 0;
    margin: 17px 0 33px;
}

.conditions__content-row {
    display: flex;
    align-items: center;
}

.conditions__content-left {
    flex: 0 0 230px;
    display: flex;
    flex-direction: column;
}

.conditions__content-right {
    flex: 1 0 auto;
}

.conditions__content-left p {
    font-size: 13px;
    line-height: 15px;
    padding: 10px 0;
    height: 34px;
    margin: 0 0 6px 0;
}

.conditions__content-left p:last-of-type {
    margin: 0;
}

.conditions__row {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 6px;
}

.conditions__row .error {
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    font-size: 11px;
    color: red;
    z-index: 1;
}

.conditions__row:last-of-type {
    margin-bottom: 0;
}

.conditions__row svg {
    position: absolute;
}

.conditions__row p {
    color: #009640;
    padding: 0 12px;
}

.conditions__form {
    flex-direction: column;
}

.conditions__buttons {
    display: flex;
    justify-content: center;
    position: relative;
    width: -webkit-max-content;
    width: max-content;
}

.conditions__buttons .lds-spinner {
    position: absolute;
    left: 10px;
    top: 7px;
    color: white;
}

.conditions__buttons .lds-spinner > div:after {
    background: white;
}

.conditions__clock-icon--1 {
    left: 115px;
}

.conditions__clock-icon--2 {
    right: 0px;
}

.conditions__input-button {
    width: 44px;
    height: 34px;
    border-width: 1px 1px 1px 0;
    border-color: #c1c1c1;
    border-style: solid;
    border-radius: 0 4px 4px 0;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conditions__input-button img {
    width: 22px;
}

.conditions__calendar {
    display: flex;
    position: relative;
}

.conditions__calendar .item__input__check {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 1px solid rgba(193, 193, 193, 0.8);
    max-width: 100px;
}

.conditions__calendar .react-datepicker-wrapper {
    max-width: 155px;
}

.conditions__time-input {
    padding: 8px 8px;
    font-size: 13px;
    line-height: 15px;
    width: 94px;
    border-radius: 4px 0 0 4px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    margin-bottom: 1px;
}

/* .conditions__content input, .conditions__content select {
    font-size: 13px;
    line-height: 15px;
    padding-left: 11px;
    height: 34px;
    border: 1px solid #c1c1c1;
} */

/* .conditions__content input::placeholder {
    color: #bfb5bd;
    font-size: 14px;
    padding-left: 12px;
} */

.conditions__select-input {
    width: 145px;
    height: 34px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    color: #313131;
    outline: none;
    word-wrap: normal !important;
    padding-left: 4px;
}

.conditions__text-input {
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    color: #bfb5bd;
}

.conditions__text-input_full-width {
    width: 100%
}

.doc-viewer {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #4F4F4F;
    -webkit-transform: unset;
            transform: unset;
    text-align: center;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 0;
}

.doc-viewer__close {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 120;
}

.doc-viewer__close img {
    transition: 0.3s ease;
}

.doc-viewer__close:hover img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.personalEditTwo_personal_edit__1eAzv .table__row {
    margin-bottom: 10px !important;
}

.personalEditTwo_personal_edit__date__1Jevb {
    display: flex;
    align-items: center;
}

.personalEditTwo_personal_edit__date__1Jevb >label {
    max-width: 104px;
}

.personalEditTwo_personal_edit__date__1Jevb >label:not(:first-child) {
    margin-left: 8px;
}
.personal {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1458px) {
    .personal {
        display: flex;
        flex-direction: column;
    }

    .personal__left {
        width: 100%;
    }
}

.personal__right table .table__icon {
    display: flex;
    justify-content: center;
}

.personal__right table thead {
    border-bottom: 1px #f2f2f2 solid;
    display: block;
}

.personal__right .table-row {
    display: grid;
    grid-template-columns: 0.5fr 7fr 5fr 6fr 100px 2fr;
    grid-gap: 8px;
    align-items: center;
    padding: 10px 20px;
}

.personal__right tbody .table-row.active,
.table__row__del > .table-row.active {
    background-color: rgb(223, 250, 240);
    border: 1px solid rgb(89, 228, 177);
}

.table__row__del > .table-row.active:hover,
.personal__right tbody .table-row.active:hover {
    background-color: rgb(223, 250, 240);
    border: 1px solid rgb(89, 228, 177);
}

.table__row__del > .table-row:hover,
.personal__right tbody .table-row:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.personal__right tr:not(:last-child) {
    border-bottom: 1px #f2f2f2 solid;
}

.personal__right .table-row th,
.personal__right .table-row tr,
.personal__right .table-row td {
    padding: 0;
    border: none;
}

.personal__right .table__row__del {
    border-top: 1px #f2f2f2 solid;
}

.personal__right .table__row__del .TableHeader {
    display: flex;
    background: white;
    justify-content: space-between;
    padding-right: 20px;
}

.personal__left,
.personal__right {
    flex: 1 1;
}

.personal__left {
    max-width: 35%;
    margin-right: 10px;
}

.personal__left .table thead {
    border-bottom: 1px #f2f2f2 solid;
    display: block;
}

.personal__left .table tbody tr:not(:last-child) {
    border-bottom: 1px #f2f2f2 solid;
}

.personal__left .table .table-row th,
.personal__left .table .table-row td {
    padding: 0;
    display: flex;
    border: none;
}

.personal__left .table .table-row {
    display: grid;
    grid-template-columns: 18px 30px 3fr 1fr 1fr;
    padding: 10px 20px;
    align-items: center;
    grid-gap: 8px;
}

.personal__right tbody .table-row .table__icon button {
    margin: 0 auto;
}

.personal__left tbody .table-row .table__icon button {
    margin: 0 auto;
}

.personal__title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #938686;
}

.personal__btn_grey {
    padding: 10px 20px;
    border: 1px solid #938686;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #938686;
    border-radius: 3px;
    background: #ffffff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.disabled-btn:disabled {
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    cursor: inherit;
}

.personal__btn_grey:hover {
    border: 1px solid #009640;
    background: #fff;
    color: #009640;
}

.personal__btn_grey:active {
    background-color: #27ae60;
    color: #fff;
}

.table-row td input[type="checkbox"]:checked {
    background: #e0f3ec;
}

.edit_personal {
    padding: 0 39px 39px;
    overflow: inherit;
}

.edit_personal__col {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.edit_personal__col input {
    width: 100%;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    height: 34px;
}

.edit_personal__col .edit_personal__input {
    flex: 1 1;
    margin-right: 16px;
}
.edit_personal__col .edit_personal__input:last-child{
    margin-right: 0;
}
.edit_personal__input label{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
    color: #535353;
}
.edit_personal__col .edit_personal__input-address {
    flex: 100% 1;
    max-width: 466px;
}
.edit_personal__col .edit_personal__input-address input {
    width: 100%;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    height: 34px;
}
.edit_personal__col .edit_personal__input-address span{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 2px;
    color: #535353;
}

.edit_personal__row {
    display: flex;
}

.edit_personal .Modal__header {
    background: #4CAF50;
    height: 50px;
    margin: 0 -39px 36px;
    padding: 0 39px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.edit_personal__row:not(:last-child) {
    margin-bottom: 40px;
}
.personal__btn_grey.disabled{
    color: rgb(193, 193, 193);
    background: rgb(242, 242, 242);
    border:1px solid rgb(242, 242, 242);
}

.edit_personal__row span {
    color: #535353;
    flex-shrink: 0;
    width: 100%;
    max-width: 160px;
    margin-right: 54px;
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.personal_conditions.conditions {
    width: 620px;
    height: auto;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}


.personal_conditions .conditions__header {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #4caf50;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.personal_conditions .conditions__close-icon {
    cursor: pointer;
}
.personal_conditions .conditions__header img:hover{
    background: none;
}

.personal_conditions .conditions__content-left {
    flex: 0 0 190px;
}

.personal_conditions .conditions__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 36px 40px 20px;
    margin: 0;
}

.personal_conditions .conditions__row {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 18px;
    width: 100%;
}

.personal_conditions .conditions__calendar {
    width: -webkit-max-content;
    width: max-content;
}

.personal_conditions .conditions__calendar-icon,
.personal_conditions .conditions__time img{
    cursor: pointer;
}
.personal_conditions .conditions__calendar label,
.personal_conditions .conditions__time label{
    display: flex;
    align-items: center;
}
.personal_conditions .conditions__time {
    position: relative;
    display: flex;
    align-items: center;
}

.personal_conditions .conditions__reward-row {
    width: -webkit-max-content;
    width: max-content;
}

.personal_conditions .conditions__row svg {
    position: absolute;
}

.personal_conditions .conditions__row p {
    color: #009640;
    padding: 0 6px;
}

.personal_conditions .conditions__calendar-icon {
    position: absolute;
    right: 0;
}

.personal_conditions .conditions__form {
    flex-direction: column;
}

.personal_conditions .conditions__content-left p {
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
}

.personal_conditions input{
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 34px;
    padding: 10px 12px;
    font-size: 13px;
    line-height: 15px;
    color: black;
    transition: 0.2s ease;
}
.personal_conditions .select {
    width: 345px;
    height: 34px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding-left: 6px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    background: white;
}

.personal_conditions .personal_conditions .conditions__content input:not(.conditions__time-input) {
    width: 164px;
    height: 34px;
    padding: 5px 12px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
}
.personal_conditions .personal_conditions .conditions__time-input{
    width: 114px;
    height: 34px;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}
.personal_conditions .personal_conditions .conditions__content input.time-start {
    width: 165px;
}
.personal_conditions .conditions__select-weight{
    width: 165px;
}
.personal_conditions .conditions__content input.time-end {
    width: 165px;
}

.personal_conditions .conditions__clock-icon--1 {
    left: 115px;
}

.personal_conditions .conditions__clock-icon--2 {
    right: 0;
}
.personal_conditions .conditions__time .react-datepicker__input-container input{
    border-radius: 5px 0 0 5px;
    border-right: 0;
    padding: 0 12px;
}
.personal_conditions .conditions__buttons{
    justify-content: flex-end;
}
.personal_conditions .conditions__time .react-datepicker__input-container input[type="text"]::-webkit-input-placeholder {

    padding-left: 0;
}
.personal_conditions .conditions__time .react-datepicker__input-container input[type="text"]::placeholder {

    padding-left: 0;
}
.personal_conditions .conditions__time .react-datepicker-wrapper{
    max-width: 115px;
}
.personal_conditions .conditions__reward {
    right: 0;
    position: absolute;
}

.personal_conditions .conditions__content input::-webkit-input-placeholder {
    color: #bfb5bd;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    padding-left: 12px;
}

.personal_conditions .conditions__content input::placeholder {
    color: #bfb5bd;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    padding-left: 12px;
}

.personal_conditions .conditions__buttons {
    margin-top: 18px;
    margin-left: auto;
}

* {
  box-sizing: border-box;
}

.employee-data {
  width: 1020px;
  height: 372px;
  font-family: Roboto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.employee-data__header {
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
}

.employee-data__section {
  display: flex;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 12px;
  font-weight: 300;
  color: #535353;
}

.employee-data__box-left {
  margin-right: 100px;
}

.employee-data__surname {
  margin-right: 15px;
}

.employee-data__name {
  margin-right: 15px;
}

.employee-data__street {
  margin-left: -45px;
  position: relative;
  margin-right: 15px;
}

.employee-data__house {
  margin-right: 15px;
}

.employee-data__search-icon {
  position: absolute;
  right: 0px;
}

.employee-data__form {
  display: flex;
}

.input {
  width: 230px;
  height: 34px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.street-input {
  width: 476px;
}

.house-input {
  width: 106px;
}

.flat-input {
  width: 106px;
}

.title {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2px;
}

.employee-data__title {
  max-width: 160px;
}

.employee-data__buttons {
  display: flex;
  margin-top: 70px;
  margin-left: 660px;
}

.shop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.shop__box-left {
  width: 400px;
  margin-top: 36px;
  margin-right: 90px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.shop__box-left-top {
  background: #ffdd94;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  width: 100%;
}
.shop__box-left-top h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.shop__box-left-top a {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #6b83ff;
}

.shop__box-left-bottom {
  padding: 22px 24px;
}
.shop__list {
  width: 100%;
}

.shop__listItem {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
.shop__listItem-title {
  flex: 4 1;
  font-size: 16px;
  line-height: 19px;
  color: #8b8b8b;
}
.shop__listItem-text {
  font-size: 16px;
  flex: 6 1;
}
.shop__box-right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.shop__box-right button:hover {
  background: #27ae60;
}

.shop__box-right button a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.edit_rent__modal {
    width: 100%;
    max-width: 1024px;
    min-height: 550px;
    overflow: hidden;
    padding: 0;
    background-color: #009640;
    display: flex;
    flex-direction: column;
}

.__react_component_tooltip.place-top:before {
    display: none;
}

.scroll-hide {
    overflow: inherit !important;
}

.scroll-controller {
    overflow: inherit !important;
    max-height: inherit !important;
}

.edit_rent__modal .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.edit_rent__modal .iMrJGR {
    display: none;
}

.edit_rent__modal > .Modal__header {
    color: white;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    background: inherit;
    padding: 10px 20px;
}


.edit_rent__tab {
    overflow-x: auto;
    max-height: 367px;
}

.edit_rent__tab--document {
    overflow: hidden;
    max-height: inherit;
}

.edit_rent__tab {
    display: none;
    flex-direction: column;
    flex: 1 1;
}

.edit_rent__tab.react-tabs__tab-panel--selected {
    display: flex;
}

.edit_rent__form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 20px;
    background: white;
    height: 100%;
}

.table-rent .TableRow:focus {
    background: green;
}

.edit_rent__row {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    margin-top: 8px;
    text-align: left;
}

.edit_rent__row > label {
    display: inline-block;
    width: 33.3%;
}

.edit_rent__row > input,
.edit_rent__row > select {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    text-align: left;
    width: 66.6%;
}

.edit_rent__menu {
    display: flex;
    list-style: none;
    padding: 0 20px;
}

.edit_rent__item {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 10px;
    text-align: center;
    display: block;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
}

.edit_rent__item.react-tabs__tab--selected {
    background: white;
    color: #007030;
}

.edit_rent__roles {
    width: 66.6%;
    float: right;
}

.edit_rent__roles > label > input {
    line-height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}

.edit_rent__roles > label {
    display: inline-block;
    line-height: 16px;
    min-width: 50%;
    -webkit-user-select: none;
            user-select: none;
    white-space: nowrap;
}

.edit_rent__condition-container {
    width: -webkit-min-content;
    width: min-content;
    margin: 0 auto;
}

.edit_rent__condition-container input {
    min-width: 140px;
    max-width: 140px;
}


.edit_rent__tab input {
    height: 34px;
}

.edit_rent__footer {
    margin-bottom: 10px;
    margin-top: auto;
    text-align: right;
}

.edit_rent__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit_rent__left {
    margin-right: 20px;
}

.edit_rent__right,
.edit_rent__left {
    flex: 1 1;
}

.edit_rent__wrapper {
    display: flex;
    position: relative;
    flex: 1 1;
}

.edit_rent__wrapper .error {
    position: absolute;
    bottom: 2px;
    left: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    font-size: 10px;
    color: red;
}

.edit_rent__home .error {
    right: 0;
    left: auto;
    width: -webkit-max-content;
    width: max-content;
}

.edit_rent-persons__head {
    border-bottom: 1px solid white;
}


.edit_rent-persons__grid {
    display: grid;
    grid-template-columns: 2fr 2fr .5fr;
    background: #F2F2F2;
}

.edit_rent-persons__row input {
    width: 100%;
}

.edit_rent-persons__grid-input {
    grid-template-columns: 4fr .5fr;
}

.edit_rent-persons__grid-input input {
    width: 100%;
}

.edit_rent__search-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.edit_rent__search-container ul {
    position: absolute;
    bottom: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    list-style: none;
    max-height: 120px;
    background: white;
    width: 100%;
    overflow-y: auto;
    padding: 5px 0;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
}

.edit_rent__search-container ul li:hover {
    background: #f2f2f2;
}

.edit_rent__search-container ul li {
    cursor: pointer;
    padding: 5px 20px;
}

.edit_rent__search-container ul li:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__list > li:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__list > li {
    display: grid;
    grid-template-columns: 100px 1fr;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #535353;
}

.edit_rent__address {
    display: grid;
    grid-template-columns: minmax(0, 1fr) -webkit-max-content;
    grid-template-columns: minmax(0, 1fr) max-content;
}

.edit_rent__address-wrapper {
    display: flex;
    align-items: center;
    margin-right: 28px;
}

.edit_rent__address-wrapper .edit_rent__wrapper {
    width: 100%;
}

.edit_rent__select {
    display: flex;
    align-items: center;
}

.edit_rent__column:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__column {
    display: flex;
    align-items: center;
}

.edit_rent__column label {
    width: 147px;
    font-size: 14px;
    line-height: 16px;
    margin-right: 10px;
    color: #535353;
    white-space: nowrap;
}

.edit_rent__condition-prolongation label {
    white-space: inherit;
}

.edit_rent__address label[for="address"] {
    margin-right: 10px;
}

.edit_rent__address label {
    font-size: 14px;
    min-width: 147px;
    line-height: 16px;
    margin-right: 28px;
    color: #535353;
    white-space: nowrap;
}

.edit_rent__home label {
    min-width: inherit;
}

.edit_rent__home input {
    max-width: 90px;
}

.edit_rent__select .select-custom {
    min-width: 308px;
}

.edit_rent__address .edit_rent__wrapper {
    align-items: center;
}


.edit_rent__container {
    display: flex;
}

.edit_rent__container:not(:last-child) {
    margin-bottom: 20px;
}

.edit_rent__list-content {
    display: flex;
    align-items: center;
}

.edit_rent__container-button {
    width: 40px;
    background: #F2F2F2;
    transition: .5s ease;
}

.edit_rent__container-button:hover {
    background: #E0E0E0;
}

.edit_rent__list-button {
    margin-left: 13px;
}

.edit_rent__list {
    list-style: none;
    padding: 14px;
    background: #F2F2F2;
    border-right: 1px solid white;
}

.edit_rent-persons:not(:last-child) {
    margin-bottom: 6px;
}

.edit_rent-persons__row {
    padding: 9px 14px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #535353;
}

.edit_rent-persons__button {
    padding: 8px 11px;
    background: #F2F2F2;
    border-radius: 3px;
    transition: .5s ease;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #313131;
}

.edit_rent-persons__button:hover {
    background: #E0E0E0;
}

.edit_rent__search {
    position: relative;
    background: #F2F2F2;
    padding: 7px 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.edit_rent__search-close {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    right: 4px;
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    border-radius: 3px;
    transition: .5s ease;
}

.edit_rent__search-close:hover {
    background: #E0E0E0;
}

.edit_rent-persons__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #938686;
}

.edit_rent-document__right {
    overflow-y: auto;
    max-height: 450px;
}

/* loader */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #009640;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009640 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

.react-viewer-navbar {
    display: none !important;
}

.react-viewer-canvas {
    background: rgb(82, 86, 89) !important;
}

.react-viewer-showTotal {
    color: white !important;
}

.react-viewer-list > li {
    width: 117px !important;
    height: 120px !important;
}

.react-viewer-list {
    height: 120px !important;
}

.react-viewer-list > li > img {
    width: 120px !important;
    height: 120px !important;
    object-fit: cover;
}

.state__form {
    display: flex;
    flex-direction: column;
}

.state__button {
    position: absolute;
    bottom: 24px;
    left: 10px;
}

.state__column {
    display: flex;
    align-items: center;
}

.state__row .quota__row__table__trash {
    background: gray;
}

.state__row label {
    font-size: 12px;
    line-height: 14px;
    color: #535353;
}

.state__row:not(:last-child) {
    margin-right: 8px;
}

.state h4 {
    margin-bottom: 24px;
}

.statistic__container {
    display: flex;
}

.statistic__row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: auto;
    grid-gap: 12px;
    gap: 12px;
    max-width: 320px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.statistic__info {
    margin: 0 auto;
}

.statistic__box {
    padding: 16px;
    background: #F2F2F2;
    border-radius: 16px;
}

.statistic__box-flex {
    display: flex;
    align-items: center;
}

.statistic__img {
    width: 61px;
    position: relative;
    min-width: 61px;
    padding-right: 26px;
    box-sizing: content-box;
    margin-right: 26px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistic__img:before {
    content: "";
    position: absolute;
    height: 130%;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 1px;
    background: #CDCDCD;
}

.statistic__info p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #535353;
    text-align: center;
}

.statistic__info span {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.controller_controller__btn__bfegG {
    background: #F2F2F2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #000000;
}

.controller_history_controller__3wcwb .Modal__header {
    background-color: #009640;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    margin: -20px -20px 20px;
}

.controller_history_controller__3wcwb {
    position: absolute;
    width: 100%;
    display: flex;
    max-height: 100%;
    max-width: 100%;
    flex-direction: column;
    top: 0;
    left: 0;
    -webkit-transform: inherit;
            transform: inherit;
    height: 100%;
    padding: 20px;
}

.controller_controller__header__L6hym li {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
}

.controller_controller__header__L6hym button {
    background: #F8F8F8;
    border-radius: 6px;
    padding: 8px;
}

.controller_controller__header__L6hym li span {
    margin-left: 8px;
    font-weight: 400;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    align-items: center;
    color: #000000;
}

.controller_controller__container__1pzNn {
    display: flex;
    flex-direction: column;
    margin: -20px;
    overflow-x: auto;
    max-height: 454px;
    padding-bottom: 30px;
}

.controller_controller__box__container__qCLTk {
    padding: 8px;
    background: #FAF9F9;
    border-radius: 6px;
    width: -webkit-max-content;
    width: max-content;
    display: flex;
}

.controller_controller__box__container__qCLTk:hover {
    background: #E0E0E0;
    transition: .5s ease;
}

.controller_controller__grid__3e5bm {
    margin-top: 20px;
}

.controller_controller__grid__3e5bm li {
    display: grid;
    grid-template-columns: -webkit-max-content 4fr 2fr -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content 4fr 2fr max-content max-content max-content;
    grid-column-gap: 30px;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
}

.controller_controller__header__L6hym {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 10px 20px;
    background: #F2F2F2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.controller_controller__bottom__15zIW {
    position: absolute;
    background: white;
    width: 100%;
    bottom: 0;
    padding: 5px 0;
}

.controller_controller__list__sn-cQ {
    list-style: none;
    margin-top: 5px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    background: #F2F2F2;
    width: 200px;
}

.controller_controller__list__sn-cQ li {
    padding: 10px;
    transition: .5s ease;
}

.controller_controller__list__sn-cQ li:hover {
    background: gray;
    cursor: pointer;
    color: white;
}

.controller_controller__list__sn-cQ li:not(:last-child) {
    margin-bottom: 10px;
}

.controller_controller__box__TvnJL {
    padding: 10px 20px;
    position: relative;
}


/*Modal*/
.controller_modal__controller__header__3Edms {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.controller_modal__controller__row__3fIsT {
    display: flex;
    align-items: center;
}

.controller_modal__controller__row__3fIsT:not(:last-child) {
    margin-bottom: 10px;
}

.controller_modal__controller__field__18RB8 {
    position: relative;
    width: 100%;
}

.controller_modal__controller__field__18RB8 .error {
    position: absolute;
    font-size: 11px;
    color: red;
    bottom: 2px;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
}

.controller_modal__controller__row__3fIsT input {
    width: 100%;
    height: 31px;
    padding: 5px 10px;

    background: #FFFFFF;
    border: 1px solid #616161;
    border-radius: 6px;
}

.controller_modal__controller__row__3fIsT p {
    width: 80px;
    margin-right: 20px;
    min-width: 80px;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.controller_modal__controller__finger__list__20iiw {
    list-style: none;
    width: 100%;
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 9px;
    gap: 9px
}

.controller_modal__controller__finger__item__TjRRi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.controller_modal__controller__finger__box__89jo0 {
    width: 63px;
    height: 63px;
    border: 1px solid #626262;
    border-radius: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
}

.controller_modal__controller__finger__button__39N-d {
    background: #C91D1D;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    padding: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.controller_modal__controller__add__394zH {
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    z-index: 30;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 20px;
    max-width: 415px;
    width: 100%;
    border-radius: 6px;
}

.style_datepicker__inline__3yz0n .react-datepicker__navigation,
.style_datepicker__inline__3yz0n .react-datepicker__current-month {
    display: none;
}

.style_datepicker__inline__3yz0n .react-datepicker__day--keyboard-selected,
.style_datepicker__inline__3yz0n .react-datepicker__day.react-datepicker__day--selected {
    z-index: 0;
}

.style_datepicker__inline__3yz0n .react-datepicker__day.history {
    z-index: 0;
}

.style_datepicker__inline__3yz0n .react-datepicker__day--keyboard-selected:before,
.style_datepicker__inline__3yz0n .react-datepicker__day.react-datepicker__day--selected:not(.style_history__23ov4):before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #61605E;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.style_datepicker__inline__3yz0n .react-datepicker__day.history:before {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #5dc469;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.style_datepicker__inline__3yz0n .react-datepicker__day--keyboard-selected,
.style_datepicker__inline__3yz0n .react-datepicker__day.react-datepicker__day--selected {
    background: none;
}

.style_datepicker__inline__3yz0n .react-datepicker__month {
    margin: 0;
}

.style_datepicker__inline__3yz0n {
    border: none;
    font-size: 16px;
    background: none;
    width: 100%;
}

.style_datepicker__inline__3yz0n .react-datepicker__month-container {
    width: 100%;
}

.style_datepicker__inline__3yz0n .react-datepicker__year-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.style_datepicker__inline__3yz0n .react-datepicker__month-read-view--down-arrow,
.style_datepicker__inline__3yz0n .react-datepicker__year-read-view--down-arrow {
    display: none;
}


.style_datepicker__inline__3yz0n .react-datepicker__month-read-view,
.style_datepicker__inline__3yz0n .react-datepicker__year-read-view {
    visibility: visible !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_datepicker__inline__3yz0n .react-datepicker__header {
    padding: 0;
    background: none;
    border: none;
}

.style_datepicker__inline__3yz0n .react-datepicker__day-name {
    font-weight: bold;
}

.style_datepicker__inline__3yz0n .react-datepicker__year-dropdown-container,
.style_datepicker__inline__3yz0n .react-datepicker__month-dropdown-container {
    flex-basis: 50%;
    display: flex;
    height: 30px;
    position: relative;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: #CCCABE;
}

.style_datepicker__inline__3yz0n .react-datepicker__year-dropdown-container:hover,
.style_datepicker__inline__3yz0n .react-datepicker__month-dropdown-container:hover {
    transition: .5s ease;
    background: rgb(108, 108, 101);
}

.style_datepicker__inline__3yz0n .react-datepicker__year-dropdown-container:hover .react-datepicker__year-read-view,
.style_datepicker__inline__3yz0n .react-datepicker__month-dropdown-container:hover .react-datepicker__month-read-view {
    color: white;
    transition: .5s ease;
}


.style_datepicker__inline__3yz0n .react-datepicker__year-dropdown, .style_datepicker__inline__3yz0n .style_datepicker__inline__3yz0n .react-datepicker__month-dropdown_, .style_datepicker__inline__3yz0n .react-datepicker__month-dropdown {
    width: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.style_datepicker__inline__3yz0n .react-datepicker__year-read-view--selected-month,
.style_datepicker__inline__3yz0n .react-datepicker__year-read-view--selected-year {
    width: 100%;
}

.style_datepicker__inline__3yz0n .react-datepicker__header__dropdown {
    display: flex;
}


.style_datepicker__inline__3yz0n .react-datepicker__day-names, .style_datepicker__inline__3yz0n .react-datepicker__week {
    display: flex;
}

.style_datepicker__inline__3yz0n .react-datepicker__day-name:hover::before,
.style_datepicker__inline__3yz0n .react-datepicker__day:hover::before,
.style_datepicker__inline__3yz0n .react-datepicker__time-name:hover::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #bebcb9;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: .5s ease;
}

.style_datepicker__inline__3yz0n .react-datepicker__day-name,
.style_datepicker__inline__3yz0n .react-datepicker__day,
.style_datepicker__inline__3yz0n .react-datepicker__time-name {
    display: flex;
    flex: 1 1;
    justify-content: center;
    width: inherit;
    z-index: 0;
    line-height: 40px;
    position: relative;
}

.info_controller__user__wrapper__2eb8A {
    display: flex;
}

.info_controller__user__row__qCD5y {
    background: #F4F1EC;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
}

.info_controller__user__text__row__3PxpA {
    display: flex;
    justify-content: space-between;
}

.info_controller__user__text__row__3PxpA p {
    font-weight: 400;
    font-size: 13px;
}

.info_controller__user__text__row__3PxpA span {
    font-weight: normal;        
}


.info_controller__user__row__qCD5y:not(:first-child) {
    margin-left: 20px;
}

.info_controller__user__detail__1A-_O {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.info_controller__user__h2__1ZAxb {
    font-size: 18px;
    color: #67674D;
    margin-bottom: 16px;
}

.info_controller__user__detail__table__2Z08G {
    width: 100%;
}

.info_controller__user__detail__table__2Z08G thead {
    display: block;
}

.info_controller__user__detail__table__2Z08G thead th {
    font-size: 16px;
}

.info_controller__user__detail__table__2Z08G tr {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 8px 20px;
    text-align: left;
}

.info_controller__user__text__2U6ep {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
    margin-top: auto;
}

.info_controller__user__scroll__2abWN {
    max-height: 242px;
    overflow-y: auto;
    display: block;
}

.info_controller__user__text__2U6ep span {
    background: white;
    color: #EC4E4D;
    padding: 2px 4px;
    border-radius: 5px;
}

.info_controller__user__box__1HH3O {
    padding: 10px 20px;
    background: #DCD9D0;
    border-radius: 5px;
}

/*.controller__user__detail__table tr:not(:last-child) {
    border-bottom: 1px solid gray;
}*/

.style_scanner__3cNEw h3 {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 2px;
    margin-top: 20px;
    color: #3fefef;
    -webkit-filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
            filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
    -webkit-animation: style_animate_text__2deQ9 0.5s steps(1) infinite;
            animation: style_animate_text__2deQ9 0.5s steps(1) infinite;
}

.style_scanner__3cNEw {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 100;
}

.style_scanner__3cNEw .style_fingarprint__3SjTy {
    position: relative;
    width: 300px;
    height: 380px;
    background: url(/static/media/image1.98c1a2d0.png);
    background-size: 300px;
}

.style_scanner__3cNEw .style_fingarprint__3SjTy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/static/media/image2.e35b3f23.png);
    background-size: 300px;
    -webkit-animation: style_animate__31dh6 4s ease-in-out infinite;
            animation: style_animate__31dh6 4s ease-in-out infinite;
}

.style_scanner__3cNEw .style_fingarprint__3SjTy::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: #3fefef;
    border-radius: 8px;
    -webkit-filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
            filter: drop-shadow(0 0 20px #3fefef) drop-shadow(0 0 60px #3fefef);
    -webkit-animation: style_animate_line__ByXnn 4s ease-in-out infinite;
            animation: style_animate_line__ByXnn 4s ease-in-out infinite;
}

@-webkit-keyframes style_animate__31dh6 {
    0%, 100% {
        height: 0;
    }

    50% {
        height: 100%;
    }
}

@keyframes style_animate__31dh6 {
    0%, 100% {
        height: 0;
    }

    50% {
        height: 100%;
    }
}

@-webkit-keyframes style_animate_line__ByXnn {
    0%,
    100% {
        top: 0;
    }
    50% {
        top: 100%;
    }
}

@keyframes style_animate_line__ByXnn {
    0%,
    100% {
        top: 0;
    }
    50% {
        top: 100%;
    }
}

@-webkit-keyframes style_animate_text__2deQ9 {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes style_animate_text__2deQ9 {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}
.controller__modal .Modal__header {
    background: green;
    margin: 0;
    width: 100%;
    padding: 5px 20px;
    color: white;
}

.Modal.controller__modal {
    padding: 0;
    max-width: 500px;
}

.controller__wrapper {
    position: relative;
}

.controller__wrapper .error {
    position: absolute;
    color: red;
    font-size: 12px;
}

.controller__bottom {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    flex-basis: 100%;
}

.controller__column__list li {
    padding: 5px 20px;
    font-size: 14px;
}

.controller__column__list li:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.controller__column__list {
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background: white;
    width: -webkit-max-content;
    width: max-content;
    position: absolute;
    list-style: none;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    max-height: 140px;
    overflow-y: auto;
}

.controller__column:not(:last-child) {
    margin-bottom: 10px;
    width: calc(50% - 10px);
}

.controller__column {
    display: flex;
    flex-direction: column;
}

.controller__column input {
    width: 100%;
}

.controller__container {
    padding: 20px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

.style_container__1W2fU {
    margin: 0 auto;
    padding: 24px 0;
    height: 100%;
}

.style_container__wrapper__wI9yr {
    display: flex;
    margin-bottom: 24px;
}

.style_container__liner__3b99k {
    background: white;
    padding: 16px 24px;
    border-radius: 16px;
}

.style_container__table__1c8cK {
    width: 100%;
    background: white;
    padding: 16px 24px;
    border-radius: 16px;
}

.style_list__3WSwP {
    display: flex;
}

.style_wrapper__2Y7GW {
    display: flex;
    align-items: center;
}

.style_custom__tooltip__img__2MIdQ {
    display: flex;
    margin-right: 8px;
}

.style_custom__tooltip__list__3XMzg {
    display: flex;
    flex-direction: column;
    color: black;
}

.style_custom__tooltip__list__3XMzg .label {
    display: flex;
}


.style_tooltips__widget__row__3PaE2 {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: #000;
}


.style_custom__tooltip__wrapper__2f_EF {
    display: flex;
    align-items: center;
}

.style_experience__line__3DUV5 {
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.style_experience__line__3DUV5 > div {
    background: #1A73E8;
    border-radius: 8px;
    height: 8px;
}

.style_experience__right__jhYYs {
    display: flex;
}

.style_custom__tooltip__3A13h > .label:not(:first-child) {
    margin-bottom: 16px;
}

.style_custom__tooltip__3A13h {
    letter-spacing: .025em;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    background: #fff;
    border: none;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    font-size: 13px;
    line-height: 16px;
    padding: 12px 16px;
}

.style_custom__tooltip__staffing__3_zJB {
    display: flex;
    flex-direction: column;
}

.style_custom__tooltip__staffing__wrapper__3QfCf {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.style_custom__tooltip__staffing__wrapper__3QfCf span {
    font-weight: 600;
    margin-left: 8px;
}

.style_custom__tooltip__staffing__top__vawbG {
    width: 100%;
    margin-bottom: 8px;
}

.style_custom__tooltip__staffing__box__2JzBP:not(:last-child) {
    margin-bottom: 8px;
}


.style_custom__tooltip__staffing__box__2JzBP {
    height: -webkit-max-content;
    height: max-content;
    padding: 8px;
    background: #C1D6FF;
    border-radius: 8px;
    max-width: 500px;
}

.style_custom__tooltip__staffing__box__red__1ESai {
    background: #FFD4D4;
}

.style_select__3Svvl {
    min-width: 107px;
}

.style_container__table__top__wrapper__Tsufl span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;
}

.style_container__table__top__wrapper__Tsufl {
    display: flex;
}

.style_container__table__top__3g4Gl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.style_list__3WSwP li:not(:last-child) {
    margin-right: 2px;
}

.style_list__3WSwP li {
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 16px;
    color: #828282;
    transition: .5s ease;
}

.style_list__3WSwP li.react-tabs__tab--selected {
    background: #E8F0FE !important;
    border-radius: 8px;
    color: #1967D2;
}

.style_list__3WSwP li:hover {
    background: #F2F2F2;
    border-radius: 8px;
    cursor: pointer;
}

.style_container__tabs__LNuf2 {
    display: flex;
    flex-direction: column;
}

.style_container__grafic__30vBA .react-tabs__tab-panel.react-tabs__tab-panel--selected {
    display: flex;
}

.style_container__grafic__30vBA .react-tabs__tab-panel {
    display: none;
}

.style_container__table__info__3Y3vR {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    max-width: 268px;
}

.style_container__table__info__column__3kajL:first-child {
    margin-right: 8px;
}


.style_container__table__info__column__3kajL {
    background: white;
    padding: 10px;
    border-radius: 16px;
    flex: 1 1;
}

.style_container__table__info__label__green__3Bx-0 {
    color: #137333;
    background: #E6F4EA;
}

.style_container__table__info__label__pink__nPNk3 {
    background: #FCE8E6;
    color: #C5221F;
}

.style_container__table__info__text__3lb8K {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px 12px;

    color: #000000;
}

.style_container__table__info__label__hOETR {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    padding: 16px 10px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_container__widget__1HI9P {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
}

.style_container__widget__diagrams__2fF-a {
    margin-right: 16px;
}

.style_container__widget__diagrams__info__2Ye83 {
    background: #FFDAF3;
    border-radius: 8px;
    padding: 8px;
}


.style_container__widget__state__top__3wLOq ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
}

.style_container__widget__state__top__3wLOq {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    color: #000000;
}

.style_container__widget__history__2jYQi table {
    border-collapse: collapse;
}


.style_container__widget__history__2jYQi table tr:before {
    content: "";
    top: 50%;
    left: -4px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    width: 7px;
    height: 7px;
    position: absolute;
    background: #137333;
    border-radius: 100%;
}

.style_container__widget__history__2jYQi table tr.deleted:before {
    background: #C5221F;
}

.style_container__widget__history__2jYQi table tr td:not(:first-child) {
    margin-right: 10px;
}

.style_container__widget__history__2jYQi table tr {
    position: relative;
    padding: 6px 0 6px 16px;
    display: flex;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.style_container__widget__history__2jYQi table tr {
    border-left: 1px solid #E0E0E0;
}

.style_container__widget__item__column__hYvHx {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
}

.style_container__widget__state__top__3wLOq h4 {
    margin-bottom: 8px !important;
}

.style_container__widget__state__top__3wLOq ul p {
    margin-right: 8px;
}

.style_container__widget__state__top__3wLOq span {
    font-weight: 600;
}

.style_container__widget__diagrams__info__2Ye83 h6:not(:last-child) {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #2E2E2E;
    margin-bottom: 4px;
}

.style_container__widget__diagrams__info__2Ye83 ul > li {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    color: #2E2E2E;
}

.style_container__widget__diagrams__info__2Ye83 ul > li span {
    font-weight: 600;
}

.style_container__widget__diagrams__info__2Ye83 ul > li:not(:last-child) {
    margin-bottom: 4px;
}

.style_container__widget__diagrams__info__2Ye83 ul {
    list-style: none;
    padding: 0px;
}

.style_container__widget__state__235Vg {
    display: flex;
    flex-direction: column;
}


.style_container__widget__1HI9P {
    grid-gap: 12px;
    gap: 12px;
}

.style_container__widget__grid__2Po9v {
    display: grid;
    width: 100%;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.style_container__widget__box__i4dkF {
    padding: 16px;
    background: #FFFFFF;
    border-radius: 16px;
}

.style_container__widget__image__text__zdeF4 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.style_container__widget__image__text__zdeF4 span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.style_container__widget__today__2Eyuv {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
}

.style_container__widget__info__2gW14 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.style_tooltips__widget__maxMin__6Kcpc > div {
    display: flex;
}

.style_tooltips__widget__maxMin__6Kcpc > div span {
    display: flex;
    width: 80px;
}

.style_tooltips__widget__maxMin__6Kcpc > div span.age {
    width: 60px;
}

.style_tooltips__widget__maxMin__6Kcpc p {
    margin-bottom: 16px;
}

.style_tooltips__widget__maxMin__6Kcpc:not(:first-child) {
    margin-right: 8px;
}

.style_custom__tooltip__grid__3s2vE > div {
    max-width: 500px;
    width: -webkit-max-content;
    width: max-content;
}

.style_custom__tooltip__grid__3s2vE {
    display: grid;
    grid-template-columns: minmax(0, -webkit-max-content)  minmax(0, -webkit-max-content);
    grid-template-columns: minmax(0, max-content)  minmax(0, max-content);
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    grid-row-gap: 12px;
    row-gap: 12px;
}

.style_custom__tooltip__grid__3s2vE .custom__tooltip__wrapper {
    flex-basis: 50%;
}

.style_tooltips__widget__maxMin__6Kcpc {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    white-space: nowrap;
    color: black;
}

.style_tooltips__widget__maxMin__wrapper__1HvFS {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}


.style_container__widget__item__3mfzj h4 span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.style_container__widget__item__3mfzj[data-for]:hover {
    cursor: help;
}

.style_container__widget__item__3mfzj h4 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 12px;
    margin-bottom: 8px;
}

.style_tooltips__widget__34UZ4 p {
    color: #000000;
}


.style_tooltips__widget__34UZ4 ul li {
    color: #000000;
    list-style: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}

.style_tooltips__widget__34UZ4 .label:not(:first-child) {
    font-size: 13px;
    line-height: 15px;
}


.style_tooltips__widget__34UZ4 ul li span {
    margin-left: 4px;
    font-weight: 600;
}

.style_tooltips__widget__34UZ4 ul li:not(:last-child) {
    margin-bottom: 4px;
}

.style_tooltips__widget__34UZ4 {
    color: #000000;
    background: #FFFFFF;
    opacity: 1 !important;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.style_container__widget__image__gmvCz img {
    height: 36px;
}

.style_container__widget__image__gmvCz {
    display: flex;
    align-items: center;
    height: 50px;
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid #CDCDCD;;
}

.style_tooltips__widget__wrapper__3GLdy {
    display: flex;
    align-items: center;
}

.style_tooltips__widget__list__2G1Bv {
    max-width: 120px;
    padding-left: 28px;
}

.style_tooltips__widget__list__2G1Bv li div {
    position: absolute;
    top: 50%;
    left: -20px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
}

.style_tooltips__widget__list__2G1Bv li p {
    display: flex;
    min-width: 50px;
    font-size: 13px;
}

.style_tooltips__widget__list__2G1Bv li {
    position: relative;
    justify-content: space-between;
}


.style_container__widget__worker__3p75l:not(:last-child) {
    margin-right: 10px;
}

.style_container__widget__item__3mfzj {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 96px;
    border-style: solid;
    border-color: #e7e7e7;
    border-width: thin;
}

.style_container__widget__wrapper__2Y8r5 {
    display: flex;
    flex: 1 1;
}


.recharts-rectangle.recharts-tooltip-cursor {
    fill: #EFEFEF !important;
    transition: .5s ease;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.popup-content {
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.user-info {
  display: flex;
  overflow-y: hidden;
  width: 900px;
}

.user-info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 470px;
}

.user-info-block-image {
  overflow: hidden;
  padding: 20px 0;
  width: 50%;
  background: #f4f4f4;
}

.user-info-block-image > span {
  color: #535353;
  font-size: 12px;
  min-width: 200px;
}

.user-info-block-text {
  width: 85%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.user-info-block-text > .title {
  justify-content: space-between;
  display: flex;
  width: 80%;
  position: relative;
  align-items: center;
}

.close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close:hover {
  border-radius: 5px;
  background-color: #e5e5e5;
}

.user-info-block-text > .title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #d7d7d7;
  top: 40px;
  left: 0;
}

.text-block {
  width: 80%;
  margin-top: 30px;
  font-size: 14px;
}

.variable-text {
  display: flex;
  margin-bottom: 5px;
}

.variable-text > .right {
  font-weight: 300;
  margin-right: 10px;
  text-align: left;
  width: 100%;
  min-width: 150px;
}

.variable-text > .left {
  text-align: left;
  width: 100%;
  min-width: 350px;
  max-width: 600px;
}

.user-photo {
  min-width: 270px;
  max-height: 320px;
  overflow: hidden;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  margin-bottom: 5px;
}

.default-icon {
  width: 170px;
  height: 170px;
}

.user-icon {
  width: 80%;
}

.qr {
  width: 130px;
  height: 130px;
}

.reference {
  padding: 24px 0 0 0;
  text-align: center;
}
.reference-search {
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
}
.reference-search > p {
  font-size: 17px;
  font-weight: 300;
  color: #4f4f4f;
  margin-bottom: 15px;
}

.reference-block {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: #4f4f4f;
  font-weight: 300;
}
.reference-block > .custom_all > .css-yto0g7-control {
  box-shadow: none;
  border: 2px solid #56ccf2;
}

.reference-block
  > .custom_all
  > .css-yto0g7-control
  > .css-t083r6
  > .css-n3csis-ValueContainer
  > .css-slim6u-singleValue {
  margin-left: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #bfb5bd;
}

.reference-block > input {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 34px;
  padding: 10px 17px;
  font-size: 13px;
  line-height: 16px;
  color: black;
  width: 325px;
  &:hover {
    border-color: #a7a7a7;
  }
  &:disabled {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
  }

  &::-webkit-input-placeholder {
    color: #bfb5bd;
  }

  &::placeholder {
    color: #bfb5bd;
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.clear_input__active {
  position: absolute;
  content: "";
  top: 15px;
  right: 38px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.clear_input {
  position: absolute;
  opacity: 0;
}

.reference-block > input:focus {
  border: 2px solid #56ccf2;
}

.reference-block .css-jxuxkg-container {
  width: 57%;
}

.btn-container {
  position: relative;
}

.reference__button {
  width: 100px;
  height: 40px;
  background: #26ae5f;
  border-radius: 2px;
  color: white;
  transition: all 0.4s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  margin: 10px 0 20px 0;
}

.clear-search {
  position: absolute;
  left: 23px;
  font-weight: 500;
  color: black;
  background: #e0e0e0;
  box-shadow: none;
}

.clear-search:hover {
  background: #f2f2f2;
  box-shadow: none;
}
.reference__button:hover {
  opacity: 0.7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}

.reference-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 400px;
  min-height: 250px;
  padding: 10px;
}

.reference-not-found > img {
  width: 100px;
  height: 100px;
}
.reference-not-found > p {
  color: #757575;
}

.reference-not-found > .p {
  font-size: 24px;
  font-weight: 700;
}

.reference > table {
  margin: 0px auto 20px auto;
  width: 50%;
  border-collapse: collapse;
  font-size: 13px;
}

.reference > table > tbody > tr {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  transition: all 0.4s;
}
.reference-block > .custom_all > .css-1uzsk4b-menu {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-5zgcwc-placeholder {
  text-align: left;
}

.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-t083r6
  > .css-5zgcwc-placeholder,
.reference-block
  > .custom_all
  > .css-1hrj4jr-control
  > .css-t083r6
  > .css-slim6u-singleValue {
  margin-left: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}

.reference > table > tbody > tr:hover {
  background: transparent;
}

.reference > table > tbody > tr > td {
  padding: 8px;
}

.reference > table > tbody > tr > td:nth-child(2) {
  text-align: left;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0px 3px 0px 40px;
  width: 78%;
}
.checkbox {
  font-size: 14px;
}
#checkAllDeleted {
  width: 21px;
  height: 21px;
  margin-right: 20px;
}

.AlertsContainer {
    position: fixed;
    width: 280px;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
}
.Alert {
    width: 300px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #27ae60;
    color: white;
    border-radius: 4px;
    margin: 20px;
}

.Alert_success {
    background-color: #27ae60;
}
.Alert_error {
    background-color: #ef5b5b;
}
.Alert_info {
    background-color: #00a2ff;
}
