.login {
  display: flex;
  height: 100vh;
  background: #ffffff;
}
.login__left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.login__logo {
  max-width: 440px;
  height: 100px;
  margin: -100px 50px 0;
}
.login__logo img {
  width: 100%;
  /* height: 68%; */
}

.login__right {
  flex: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #109e52;
}
.login__form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__title {
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 20px;
}
.login__input {
  width: 320px;
  height: 50px;
  margin-bottom: 2px;
  border: none;
  padding: 15px 0 15px 23px;
  border-radius: 3px;
  outline: none;
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}
.login__input_error {
  border: 3px solid red
}
.login__input::placeholder {
  color: #a59696;
}
.login__error {
  width: 320px;
  height: 15px;
  margin-bottom: 5px;
  text-align: left;
  color: red;
  font-size: 12px;
}
.login__btn {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: bold;
  font-size: 22px;
  border-radius: 3px;
  padding: 12px 30px;
  background: #ffff;
}
.login__btn a {
  color: #109e52;
  text-decoration: none;
}
