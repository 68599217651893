.datepicker__inline :global(.react-datepicker__navigation),
.datepicker__inline :global(.react-datepicker__current-month) {
    display: none;
}

.datepicker__inline :global(.react-datepicker__day--keyboard-selected),
.datepicker__inline :global(.react-datepicker__day.react-datepicker__day--selected) {
    z-index: 0;
}

.datepicker__inline :global(.react-datepicker__day.history) {
    z-index: 0;
}

.datepicker__inline :global(.react-datepicker__day--keyboard-selected):before,
.datepicker__inline :global(.react-datepicker__day.react-datepicker__day--selected):not(.history):before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #61605E;
    transform: translate(-50%, -50%);
}

.datepicker__inline :global(.react-datepicker__day.history):before {
    content: '';
    position: absolute;
    width: 34px;
    height: 34px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #5dc469;
    transform: translate(-50%, -50%);
}

.datepicker__inline :global(.react-datepicker__day--keyboard-selected),
.datepicker__inline :global(.react-datepicker__day.react-datepicker__day--selected) {
    background: none;
}

.datepicker__inline :global(.react-datepicker__month) {
    margin: 0;
}

.datepicker__inline {
    border: none;
    font-size: 16px;
    background: none;
    width: 100%;
}

.datepicker__inline :global(.react-datepicker__month-container) {
    width: 100%;
}

.datepicker__inline :global(.react-datepicker__year-dropdown) {
    max-height: 200px;
    overflow-y: auto;
}

.datepicker__inline :global(.react-datepicker__month-read-view--down-arrow),
.datepicker__inline :global(.react-datepicker__year-read-view--down-arrow) {
    display: none;
}


.datepicker__inline :global(.react-datepicker__month-read-view),
.datepicker__inline :global(.react-datepicker__year-read-view) {
    visibility: visible !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datepicker__inline :global(.react-datepicker__header) {
    padding: 0;
    background: none;
    border: none;
}

.datepicker__inline :global(.react-datepicker__day-name) {
    font-weight: bold;
}

.datepicker__inline :global(.react-datepicker__year-dropdown-container),
.datepicker__inline :global(.react-datepicker__month-dropdown-container) {
    flex-basis: 50%;
    display: flex;
    height: 30px;
    position: relative;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background: #CCCABE;
}

.datepicker__inline :global(.react-datepicker__year-dropdown-container):hover,
.datepicker__inline :global(.react-datepicker__month-dropdown-container):hover {
    transition: .5s ease;
    background: rgb(108, 108, 101);
}

.datepicker__inline :global(.react-datepicker__year-dropdown-container):hover :global(.react-datepicker__year-read-view),
.datepicker__inline :global(.react-datepicker__month-dropdown-container):hover :global(.react-datepicker__month-read-view) {
    color: white;
    transition: .5s ease;
}


.datepicker__inline :global(.react-datepicker__year-dropdown), .datepicker__inline .datepicker__inline :global(.react-datepicker__month-dropdown_), .datepicker__inline :global(.react-datepicker__month-dropdown) {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

.datepicker__inline :global(.react-datepicker__year-read-view--selected-month),
.datepicker__inline :global(.react-datepicker__year-read-view--selected-year) {
    width: 100%;
}

.datepicker__inline :global(.react-datepicker__header__dropdown) {
    display: flex;
}


.datepicker__inline :global(.react-datepicker__day-names), .datepicker__inline :global(.react-datepicker__week) {
    display: flex;
}

.datepicker__inline :global(.react-datepicker__day-name):hover:global(::before),
.datepicker__inline :global(.react-datepicker__day):hover:global(::before),
.datepicker__inline :global(.react-datepicker__time-name):hover:global(::before) {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    z-index: -1;
    border-radius: 100%;
    top: 50%;
    background: #bebcb9;
    transform: translate(-50%, -50%);
    transition: .5s ease;
}

.datepicker__inline :global(.react-datepicker__day-name),
.datepicker__inline :global(.react-datepicker__day),
.datepicker__inline :global(.react-datepicker__time-name) {
    display: flex;
    flex: 1 1;
    justify-content: center;
    width: inherit;
    z-index: 0;
    line-height: 40px;
    position: relative;
}
