.controller__modal .Modal__header {
    background: green;
    margin: 0;
    width: 100%;
    padding: 5px 20px;
    color: white;
}

.Modal.controller__modal {
    padding: 0;
    max-width: 500px;
}

.controller__wrapper {
    position: relative;
}

.controller__wrapper .error {
    position: absolute;
    color: red;
    font-size: 12px;
}

.controller__bottom {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    flex-basis: 100%;
}

.controller__column__list li {
    padding: 5px 20px;
    font-size: 14px;
}

.controller__column__list li:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.controller__column__list {
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    background: white;
    width: max-content;
    position: absolute;
    list-style: none;
    transform: translate(0, 100%);
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    max-height: 140px;
    overflow-y: auto;
}

.controller__column:not(:last-child) {
    margin-bottom: 10px;
    width: calc(50% - 10px);
}

.controller__column {
    display: flex;
    flex-direction: column;
}

.controller__column input {
    width: 100%;
}

.controller__container {
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
