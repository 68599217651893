.personal_conditions.conditions {
    width: 620px;
    height: auto;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}


.personal_conditions .conditions__header {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #4caf50;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.personal_conditions .conditions__close-icon {
    cursor: pointer;
}
.personal_conditions .conditions__header img:hover{
    background: none;
}

.personal_conditions .conditions__content-left {
    flex: 0 0 190px;
}

.personal_conditions .conditions__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 36px 40px 20px;
    margin: 0;
}

.personal_conditions .conditions__row {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 18px;
    width: 100%;
}

.personal_conditions .conditions__calendar {
    width: max-content;
}

.personal_conditions .conditions__calendar-icon,
.personal_conditions .conditions__time img{
    cursor: pointer;
}
.personal_conditions .conditions__calendar label,
.personal_conditions .conditions__time label{
    display: flex;
    align-items: center;
}
.personal_conditions .conditions__time {
    position: relative;
    display: flex;
    align-items: center;
}

.personal_conditions .conditions__reward-row {
    width: max-content;
}

.personal_conditions .conditions__row svg {
    position: absolute;
}

.personal_conditions .conditions__row p {
    color: #009640;
    padding: 0 6px;
}

.personal_conditions .conditions__calendar-icon {
    position: absolute;
    right: 0;
}

.personal_conditions .conditions__form {
    flex-direction: column;
}

.personal_conditions .conditions__content-left p {
    margin-bottom: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
}

.personal_conditions input{
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 34px;
    padding: 10px 12px;
    font-size: 13px;
    line-height: 15px;
    color: black;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}
.personal_conditions .select {
    width: 345px;
    height: 34px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding-left: 6px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    background: white;
}

.personal_conditions .personal_conditions .conditions__content input:not(.conditions__time-input) {
    width: 164px;
    height: 34px;
    padding: 5px 12px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
}
.personal_conditions .personal_conditions .conditions__time-input{
    width: 114px;
    height: 34px;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}
.personal_conditions .personal_conditions .conditions__content input.time-start {
    width: 165px;
}
.personal_conditions .conditions__select-weight{
    width: 165px;
}
.personal_conditions .conditions__content input.time-end {
    width: 165px;
}

.personal_conditions .conditions__clock-icon--1 {
    left: 115px;
}

.personal_conditions .conditions__clock-icon--2 {
    right: 0;
}
.personal_conditions .conditions__time .react-datepicker__input-container input{
    border-radius: 5px 0 0 5px;
    border-right: 0;
    padding: 0 12px;
}
.personal_conditions .conditions__buttons{
    justify-content: flex-end;
}
.personal_conditions .conditions__time .react-datepicker__input-container input[type="text"]::placeholder {

    padding-left: 0;
}
.personal_conditions .conditions__time .react-datepicker-wrapper{
    max-width: 115px;
}
.personal_conditions .conditions__reward {
    right: 0;
    position: absolute;
}

.personal_conditions .conditions__content input::placeholder {
    color: #bfb5bd;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    padding-left: 12px;
}

.personal_conditions .conditions__buttons {
    margin-top: 18px;
    margin-left: auto;
}
