.shop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.shop__box-left {
  width: 400px;
  margin-top: 36px;
  margin-right: 90px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.shop__box-left-top {
  background: #ffdd94;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  width: 100%;
}
.shop__box-left-top h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.shop__box-left-top a {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: #6b83ff;
}

.shop__box-left-bottom {
  padding: 22px 24px;
}
.shop__list {
  width: 100%;
}

.shop__listItem {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
.shop__listItem-title {
  flex: 4;
  font-size: 16px;
  line-height: 19px;
  color: #8b8b8b;
}
.shop__listItem-text {
  font-size: 16px;
  flex: 6;
}
.shop__box-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.shop__box-right button:hover {
  background: #27ae60;
}

.shop__box-right button a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
