.Modal {
    max-height: 85vh;
    background-color: #fff;
    padding: 6px 28px 38px;
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1000px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
}
.Modal.transfer{
    overflow-y: inherit;
}
.Modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: black;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 4px;
}

.Modal__content {
    margin: 22px 0 0 0;
    display: grid;
    grid-template-columns: 210fr 314fr;
    gap: 6px 20px;
}

.Modal__text {
    align-self: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #313131;
}

.Modal__code {
    align-self: center;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #313131;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}

.Modal__button-row {
    justify-content: center;
    margin-top: 26px;
    width: 100%;
    display: flex;
}
.Modal__button-link {
    text-decoration: none;
}
