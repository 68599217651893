.user-info {
  display: flex;
  overflow-y: hidden;
  width: 900px;
}

.user-info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 470px;
}

.user-info-block-image {
  overflow: hidden;
  padding: 20px 0;
  width: 50%;
  background: #f4f4f4;
}

.user-info-block-image > span {
  color: #535353;
  font-size: 12px;
  min-width: 200px;
}

.user-info-block-text {
  width: 85%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.user-info-block-text > .title {
  justify-content: space-between;
  display: flex;
  width: 80%;
  position: relative;
  align-items: center;
}

.close {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.close:hover {
  border-radius: 5px;
  background-color: #e5e5e5;
}

.user-info-block-text > .title::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #d7d7d7;
  top: 40px;
  left: 0;
}

.text-block {
  width: 80%;
  margin-top: 30px;
  font-size: 14px;
}

.variable-text {
  display: flex;
  margin-bottom: 5px;
}

.variable-text > .right {
  font-weight: 300;
  margin-right: 10px;
  text-align: left;
  width: 100%;
  min-width: 150px;
}

.variable-text > .left {
  text-align: left;
  width: 100%;
  min-width: 350px;
  max-width: 600px;
}

.user-photo {
  min-width: 270px;
  max-height: 320px;
  overflow: hidden;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  margin-bottom: 5px;
}

.default-icon {
  width: 170px;
  height: 170px;
}

.user-icon {
  width: 80%;
}

.qr {
  width: 130px;
  height: 130px;
}
