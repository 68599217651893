* {
  box-sizing: border-box;
}

.employee-data {
  width: 1020px;
  height: 372px;
  font-family: Roboto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.employee-data__header {
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
}

.employee-data__section {
  display: flex;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 12px;
  font-weight: 300;
  color: #535353;
}

.employee-data__box-left {
  margin-right: 100px;
}

.employee-data__surname {
  margin-right: 15px;
}

.employee-data__name {
  margin-right: 15px;
}

.employee-data__street {
  margin-left: -45px;
  position: relative;
  margin-right: 15px;
}

.employee-data__house {
  margin-right: 15px;
}

.employee-data__search-icon {
  position: absolute;
  right: 0px;
}

.employee-data__form {
  display: flex;
}

.input {
  width: 230px;
  height: 34px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.street-input {
  width: 476px;
}

.house-input {
  width: 106px;
}

.flat-input {
  width: 106px;
}

.title {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2px;
}

.employee-data__title {
  max-width: 160px;
}

.employee-data__buttons {
  display: flex;
  margin-top: 70px;
  margin-left: 660px;
}
