.edit_user__modal {
    padding: 0;
}
.edit_user__modal > .Modal__header {
    background-color: #009640;
    color:  white;
    font-size: 16px;
    margin-bottom:  0;
    padding:  10px 20px;
    margin-top: 0px;
}
.edit_user__modal > .edit_user__form {
    padding: 12px 20px;
}
.edit_user__form {
    display: flex;
    flex-direction: column;
}
.edit_user__row {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    margin-top: 8px;
    text-align: left;
    position: relative;
}
.edit_user__row > label {
    display:  inline-block;
    width: 33.3%;
}
.edit_user__row > input,
.edit_user__row > select {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    text-align: left;
    width: 66.6%;
}

.edit_user__row > input.telephone {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 32px;
    padding: 10px 17px;
    font-size: 13px;
    line-height: 16px;
    color: black;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

.edit_user__row p {
    position: absolute;
    transform: translate(0, 100%);
    right: 0;
    bottom: 12px;
    color: red;
    font-size: 10px;
}

.edit_user__row > input.error {
    border: 1px solid red;
}

.edit_user__roles {
    width: 66.6%;
    float: right;
}

.edit_user__roles > label > input {
    line-height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}
.edit_user__roles > label {
    display:  inline-block;
    line-height: 16px;
    min-width: 50%;
    user-select: none;
    white-space:  nowrap;
}
.edit_user__footer {
    margin-bottom:  10px;
    margin-top: 26px;
    text-align:  right;
}
