.StartCard {
    /* width: 100%;
    height: auto; */
    position: relative;
    background: #FFFFFF;
    border: 1px solid #DBE7EF;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 20px 27px 20px;
    /* margin-bottom: 50px; */
}

.StartCard__button-row {
    display: flex;
}