.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0;
  border-radius: 0 !important;
  width: 30px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background: #4caf50;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4caf50;
}
.react-datepicker__input-container input:disabled {
  background-color: #f2f2f2 !important;
  color: black;
  pointer-events: none;
}
.react-datepicker__input-container input {
  width: 100%;
  min-width: 100px;
  height: 34px;
  background: #ffffff;
  border: 1px solid rgba(193, 193, 193, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 18px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  opacity: 0;
  width: 35%;
  outline: 0;
  position: absolute;
  left: 95px;
  top: 0;
  right: 0px;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker {
  border-radius: 0 !important;
  border-color: #4caf50;
}

.react-datepicker__triangle {
  display: none;
}
