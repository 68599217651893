.personal_edit :global(.table__row) {
    margin-bottom: 10px !important;
}

.personal_edit__date {
    display: flex;
    align-items: center;
}

.personal_edit__date :global(>label) {
    max-width: 104px;
}

.personal_edit__date :global(>label:not(:first-child)) {
    margin-left: 8px;
}