.Input {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 15px;
    box-sizing: content-box;
    color: #535353;
    /* transition: .2s linear; */
    margin: 2px; 
    padding-left: 12px
}
.Input:hover {
    border: 1px solid #A4A4A4;
}
.Input:focus {
    border: 3px solid #56CCF2;
    margin: 0;
}
.Input::placeholder {
    color: #BFB5BD
}