.table__conditions {
    max-width: 500px;
    margin: 0 auto;
}

.table__conditions_column {
    display: flex;
    align-items: center;
    width: 100%;
}

.table__conditions_column:not(:last-child) {
    margin-bottom: 8px;
}

.table__conditions-select {
    border: 1px solid rgba(193, 193, 193, 0.8);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 18px;
    height: 28px;
}

.table__conditions_field {
    position: relative;
}

.table__conditions_field .error {
    position: absolute;
    color: red;
    font-size: 9px;
    left: 0;
}

.table__conditions input:focus {
    border: 1px solid black;
}

.table__conditions input:hover {
    border: 1px solid #7c7c7c;
}

.select__conditions {
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 17px;
    font-size: 13px;
    width: 100%;
}

.table__conditions_group .small-input {
    max-width: max-content;
    width: 60px;
    text-align: center;
    margin-left: 8px;
}

.table__conditions_group {
    display: flex;
}

.table__conditions input:disabled {
    background-color: #f2f2f2 !important;
    color: black;
    pointer-events: none;
}

.table__conditions input {
    background: #ffffff;
    width: 200px;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 4px;
    height: 28px;
    padding: 10px 17px;
    font-size: 13px;
    line-height: 16px;
    color: black;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}

.table__conditions .react-datepicker__input-container input {
    height: 28px;
    width: 200px;
}

.table__conditions-date > label:not(:first-child) {
    margin-left: 8px;
}

.table__conditions-date .react-datepicker__input-container input {
    width: 80px;
    padding: 5px;
}

.table__conditions_label {
    width: 160px;
    min-width: 160px;
    font-size: 13px;
    margin-right: 20px;
}