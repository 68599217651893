.header {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 0 calc((100% - 1550px) / 2);
  box-sizing: border-box;
  grid-template-areas: 
    "header__logo header__links header__user"; 
}

@media (max-width: 1600px) {
  .header {
    padding: 0 25px
  }
}

/* LOGO */


/* Links */
.header__logo {
  grid-area: header__logo;
  justify-self: left;
  align-self: center;
}

.header__links {
  grid-area: header__links;
  justify-self: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.header__links ul {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__links ul li {
  height: 100%;
  list-style: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.header__links ul li a {
  color: #bbbbbb;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28px;
}

.header__links ul li a:hover {
  color: #009640;
  background-color: #eeeeee;
}

.header__links ul li .header__links_active {
  color: #009640;
  border-bottom: 3px #27ae60 solid;
  border-radius: 4px 4px 0px 0px;
  background: rgba(0, 150, 64, 0.1);
}

/* USER */

.header__user {
  grid-area: header__user;
  justify-self: right;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.header__user-img svg path {
  transition: all 0.2s ease-in-out;
}
.header__user:hover .header__user-name {
  color: #009640;
}
.header__user:hover .header__user-img svg path {
  fill: #009640;
}
.header__user-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #938686;
  margin-right: 28px;
  transition: all 0.2s ease-in-out;
}
.header__user:focus .header__drop,
.header__drop:hover {
  display: flex;
}
.header__user-name:hover {
  color: #009640;
}
.header__user-name:hover + .header__user-img svg path {
  fill: #009640;
}
.header__drop {
  position: absolute;
  top: 60px;
  right: 0;
  background: white;
  width: 100%;
  padding: 5px 0;
  display: none;
  flex-direction: column;
  z-index: 100;
  align-items: flex-start;
  transition: 0.2s ease;
  box-shadow: 0px 0px 5px #ccc;
}
.header__drop .header__dropItem {
  padding: 4px 12px;
  color: #009640;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  transition: 0.2s ease;
  text-align: left;
  text-decoration: none;
}
.header__drop .header__dropItem:hover {
  background-color: #eeeeee;
}
.header__drop .header__dropItem:first-child {
  margin-bottom: 5px;
}
