.edit_rent__modal {
    width: 100%;
    max-width: 1024px;
    min-height: 550px;
    overflow: hidden;
    padding: 0;
    background-color: #009640;
    display: flex;
    flex-direction: column;
}

.__react_component_tooltip.place-top:before {
    display: none;
}

.scroll-hide {
    overflow: inherit !important;
}

.scroll-controller {
    overflow: inherit !important;
    max-height: inherit !important;
}

.edit_rent__modal .react-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.edit_rent__modal .iMrJGR {
    display: none;
}

.edit_rent__modal > .Modal__header {
    color: white;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    background: inherit;
    padding: 10px 20px;
}


.edit_rent__tab {
    overflow-x: auto;
    max-height: 367px;
}

.edit_rent__tab--document {
    overflow: hidden;
    max-height: inherit;
}

.edit_rent__tab {
    display: none;
    flex-direction: column;
    flex: 1 1;
}

.edit_rent__tab.react-tabs__tab-panel--selected {
    display: flex;
}

.edit_rent__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    background: white;
    height: 100%;
}

.table-rent .TableRow:focus {
    background: green;
}

.edit_rent__row {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    margin-top: 8px;
    text-align: left;
}

.edit_rent__row > label {
    display: inline-block;
    width: 33.3%;
}

.edit_rent__row > input,
.edit_rent__row > select {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 34px;
    text-align: left;
    width: 66.6%;
}

.edit_rent__menu {
    display: flex;
    list-style: none;
    padding: 0 20px;
}

.edit_rent__item {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 10px;
    text-align: center;
    display: block;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
}

.edit_rent__item.react-tabs__tab--selected {
    background: white;
    color: #007030;
}

.edit_rent__roles {
    width: 66.6%;
    float: right;
}

.edit_rent__roles > label > input {
    line-height: 16px;
    margin-right: 4px;
    vertical-align: middle;
}

.edit_rent__roles > label {
    display: inline-block;
    line-height: 16px;
    min-width: 50%;
    user-select: none;
    white-space: nowrap;
}

.edit_rent__condition-container {
    width: min-content;
    margin: 0 auto;
}

.edit_rent__condition-container input {
    min-width: 140px;
    max-width: 140px;
}


.edit_rent__tab input {
    height: 34px;
}

.edit_rent__footer {
    margin-bottom: 10px;
    margin-top: auto;
    text-align: right;
}

.edit_rent__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edit_rent__left {
    margin-right: 20px;
}

.edit_rent__right,
.edit_rent__left {
    flex: 1;
}

.edit_rent__wrapper {
    display: flex;
    position: relative;
    flex: 1 1;
}

.edit_rent__wrapper .error {
    position: absolute;
    bottom: 2px;
    left: 0;
    transform: translate(0, 100%);
    font-size: 10px;
    color: red;
}

.edit_rent__home .error {
    right: 0;
    left: auto;
    width: max-content;
}

.edit_rent-persons__head {
    border-bottom: 1px solid white;
}


.edit_rent-persons__grid {
    display: grid;
    grid-template-columns: 2fr 2fr .5fr;
    background: #F2F2F2;
}

.edit_rent-persons__row input {
    width: 100%;
}

.edit_rent-persons__grid-input {
    grid-template-columns: 4fr .5fr;
}

.edit_rent-persons__grid-input input {
    width: 100%;
}

.edit_rent__search-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.edit_rent__search-container ul {
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    list-style: none;
    max-height: 120px;
    background: white;
    width: 100%;
    overflow-y: auto;
    padding: 5px 0;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
}

.edit_rent__search-container ul li:hover {
    background: #f2f2f2;
}

.edit_rent__search-container ul li {
    cursor: pointer;
    padding: 5px 20px;
}

.edit_rent__search-container ul li:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__list > li:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__list > li {
    display: grid;
    grid-template-columns: 100px 1fr;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #535353;
}

.edit_rent__address {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
}

.edit_rent__address-wrapper {
    display: flex;
    align-items: center;
    margin-right: 28px;
}

.edit_rent__address-wrapper .edit_rent__wrapper {
    width: 100%;
}

.edit_rent__select {
    display: flex;
    align-items: center;
}

.edit_rent__column:not(:last-child) {
    margin-bottom: 10px;
}

.edit_rent__column {
    display: flex;
    align-items: center;
}

.edit_rent__column label {
    width: 147px;
    font-size: 14px;
    line-height: 16px;
    margin-right: 10px;
    color: #535353;
    white-space: nowrap;
}

.edit_rent__condition-prolongation label {
    white-space: inherit;
}

.edit_rent__address label[for="address"] {
    margin-right: 10px;
}

.edit_rent__address label {
    font-size: 14px;
    min-width: 147px;
    line-height: 16px;
    margin-right: 28px;
    color: #535353;
    white-space: nowrap;
}

.edit_rent__home label {
    min-width: inherit;
}

.edit_rent__home input {
    max-width: 90px;
}

.edit_rent__select .select-custom {
    min-width: 308px;
}

.edit_rent__address .edit_rent__wrapper {
    align-items: center;
}


.edit_rent__container {
    display: flex;
}

.edit_rent__container:not(:last-child) {
    margin-bottom: 20px;
}

.edit_rent__list-content {
    display: flex;
    align-items: center;
}

.edit_rent__container-button {
    width: 40px;
    background: #F2F2F2;
    transition: .5s ease;
}

.edit_rent__container-button:hover {
    background: #E0E0E0;
}

.edit_rent__list-button {
    margin-left: 13px;
}

.edit_rent__list {
    list-style: none;
    padding: 14px;
    background: #F2F2F2;
    border-right: 1px solid white;
}

.edit_rent-persons:not(:last-child) {
    margin-bottom: 6px;
}

.edit_rent-persons__row {
    padding: 9px 14px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #535353;
}

.edit_rent-persons__button {
    padding: 8px 11px;
    background: #F2F2F2;
    border-radius: 3px;
    transition: .5s ease;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #313131;
}

.edit_rent-persons__button:hover {
    background: #E0E0E0;
}

.edit_rent__search {
    position: relative;
    background: #F2F2F2;
    padding: 7px 14px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.edit_rent__search-close {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 4px;
    height: max-content;
    display: flex;
    border-radius: 3px;
    transition: .5s ease;
}

.edit_rent__search-close:hover {
    background: #E0E0E0;
}

.edit_rent-persons__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #938686;
}

.edit_rent-document__right {
    overflow-y: auto;
    max-height: 450px;
}

/* loader */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #009640;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009640 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

.react-viewer-navbar {
    display: none !important;
}

.react-viewer-canvas {
    background: rgb(82, 86, 89) !important;
}

.react-viewer-showTotal {
    color: white !important;
}

.react-viewer-list > li {
    width: 117px !important;
    height: 120px !important;
}

.react-viewer-list {
    height: 120px !important;
}

.react-viewer-list > li > img {
    width: 120px !important;
    height: 120px !important;
    object-fit: cover;
}

.state__form {
    display: flex;
    flex-direction: column;
}

.state__button {
    position: absolute;
    bottom: 24px;
    left: 10px;
}

.state__column {
    display: flex;
    align-items: center;
}

.state__row .quota__row__table__trash {
    background: gray;
}

.state__row label {
    font-size: 12px;
    line-height: 14px;
    color: #535353;
}

.state__row:not(:last-child) {
    margin-right: 8px;
}

.state h4 {
    margin-bottom: 24px;
}

.statistic__container {
    display: flex;
}

.statistic__row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-left: auto;
    gap: 12px;
    max-width: 320px;
    position: sticky;
    top: 0;
}

.statistic__info {
    margin: 0 auto;
}

.statistic__box {
    padding: 16px;
    background: #F2F2F2;
    border-radius: 16px;
}

.statistic__box-flex {
    display: flex;
    align-items: center;
}

.statistic__img {
    width: 61px;
    position: relative;
    min-width: 61px;
    padding-right: 26px;
    box-sizing: content-box;
    margin-right: 26px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistic__img:before {
    content: "";
    position: absolute;
    height: 130%;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 1px;
    background: #CDCDCD;
}

.statistic__info p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #535353;
    text-align: center;
}

.statistic__info span {
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
